import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Button, Card, CardBody, CardHeader, InputForm, TitleText } from "components";
import { BiPencil } from "react-icons/bi";
import { useFormContext } from "react-hook-form";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

const SocialMediaInformation = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {}
}) => {
  const navigate = useNavigate();

  const { 
    watch, 
    getValues,
    formState: { errors, isValid } 
  } = useFormContext();

  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEditVerification = () => setIsEditVerification(!isEditVerification)

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Website & Social Media</div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputForm
            controllerName={"general_information.general_social_media.website"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={'Website'}
            placeholder={'Website'}
            required
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.general_social_media.instagram"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={'Instagram'}
            placeholder={'Instagram'}
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.general_social_media.facebook"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={'Facebook'}
            placeholder={'Facebook'}
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.general_social_media.twitter"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={'Twitter'}
            placeholder={'Twitter'}
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.general_social_media.youtube"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={'Youtube'}
            placeholder={'Youtube'}
            disabled={!isEdit}
          />
        </div>
        {/* button Verification */}
        <RadioVerificationBadge 
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"general_information.general_social_media.status_approval_social_media"}
          nameNotes={"general_information.general_social_media.notes_approval_social_media"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.general_information?.general_social_media?.status_approval_social_media ? errors?.general_information?.general_social_media?.status_approval_social_media : false}
        />
      </CardBody>
    </Card>
  );
};

export default SocialMediaInformation;