import {
  AtomDatePicker,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormUpload,
} from "components";
import { kebabCase } from "lodash";
import { UploadImage } from "pages/Tor/assets";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

import CustomSelectForm from "components/molecules/CustomSelectForm";
import {
  useImplementingPartnerDetail,
  useImplementingPartnerList,
} from "pages/ImplementingPartner/hooks/useImplementingPartnerServices";
import {
  useBudgetAccountList,
  useCreateImplementingPartnerPayment,
  useImplementingPartnerPaymentDetail,
  useInvestmentPlanList,
  useMainActivityList,
  useTaxTypeList,
} from "../hooks/useImplementingPartnerPaymentServices";
import moment from "moment";
import { formatTotalCost } from "pages/Tor/helpers";
import { fileUpload } from "services/danaProgram/fileService";
import InputCurrency from "components/molecules/InputCurrency";

const links = [
  {
    label: "Daftar Pembayaran Implementing Partner",
    path: "/implementing-partner-payment",
  },
  {
    label: "Ubah data implementing partner",
  },
];

const ImplementingPartnerPaymentEdit = () => {
  const { id } = useParams();
  const { detail, detailIsLoading: paymentIsLoading } =
    useImplementingPartnerPaymentDetail(id);

  const methods = useForm({
    defaultValues: {},
  });

  const navigate = useNavigate();

  const [detailActivity, setDetailActivity] = useState([]);
  const [implementingPartnerId, setImplementingPartnerId] = useState(null);

  const { submitMutation } = useCreateImplementingPartnerPayment();
  const {
    list: implementingPartnerList,
    isLoading: implementingPartnerIsLoading,
  } = useImplementingPartnerList({
    limit: 999,
    page: 1,
  });

  const { detail: implementingPartnerDetail, detailIsLoading } =
    useImplementingPartnerDetail(implementingPartnerId);

  const { list: mainActivityList, isLoading: mainActivityIsLoading } =
    useMainActivityList();
  const { list: taxTypeList, isLoading: taxTypeIsLoading } = useTaxTypeList();
  const { list: budgetAccountList, isLoading: budgetAccountIsLoading } =
    useBudgetAccountList();

  const watchRPayment = methods.watch("required_payment");
  const watchTax = methods.watch("tax");
  const taxValue = methods.watch("tax_value");

  useEffect(() => {
    if (!detailIsLoading && implementingPartnerDetail) {
      const bankData = implementingPartnerDetail.bank;

      methods.setValue("implementing_partner_id", {
        value: implementingPartnerDetail._id,
        label: implementingPartnerDetail.name,
      });
      methods.setValue(
        "account_number",
        implementingPartnerDetail.account_number
      );
      methods.setValue(
        "account_owner_name",
        implementingPartnerDetail.account_owner_name
      );
      methods.setValue("bank", bankData.name);
      methods.setValue("bank_detail", bankData);
      methods.setValue("branch", implementingPartnerDetail.branch);
    }
  }, [methods, detailIsLoading, implementingPartnerDetail]);

  useEffect(() => {
    const tax = methods.watch("tax");
    const taxCalculation =
      parseInt(watchRPayment) * (((tax && tax?.tax) || 0) / 100);

    methods.setValue("tax_value", isNaN(taxCalculation) ? 0 : taxCalculation);
  }, [methods, watchRPayment, watchTax]);

  useEffect(() => {
    const totalPaymentCalculation =
      parseInt(watchRPayment) + parseInt(taxValue);
    methods.setValue(
      "total_payment",
      isNaN(totalPaymentCalculation) ? 0 : totalPaymentCalculation
    );
  }, [methods, watchRPayment, taxValue]);

  useEffect(() => {
    if (!paymentIsLoading && detail) {
      setImplementingPartnerId(detail.implementing_partner_id);

      methods.setValue("implementing_partner_project_name", {
        value: detail?.implementing_partner_project_id,
        label: detail?.implementing_partner_project_name,
      });
      methods.setValue("desc", detail.desc);
      methods.setValue(
        "transaction_date",
        moment(detail.transaction_date, "DD/MM/YYYY").toDate()
      );
      methods.setValue("invoice_number", detail.invoice_number);
      methods.setValue("total_payment", detail.total_payment);
      methods.setValue("primary_activity", {
        value: detail.primary_activity.id,
        label: detail.primary_activity.name,
      });
      methods.setValue("detail_activity", {
        value: detail.detail_activity.id,
        label: detail.detail_activity.name,
      });
      methods.setValue("currency", {
        value: detail.currency.id,
        label: detail.currency.name,
      });
      methods.setValue("required_payment", detail.required_payment);
      methods.setValue("tax_value", detail.tax.value);
      methods.setValue("billing_code", detail.billing_code);
      methods.setValue("supporting_doc", detail.supporting_doc);
      methods.setValue("status", detail.status);
    }
  }, [methods, paymentIsLoading, detail]);

  useEffect(() => {
    if (!taxTypeIsLoading) {
      let selectedTax = taxTypeList.find((item) => item.id === detail?.tax?.id);
      methods.setValue("tax", {
        value: selectedTax.id,
        label: selectedTax.nama,
        tax: selectedTax.value,
      });
    }
  }, [methods, taxTypeIsLoading, taxTypeList, detail]);

  const handleSubmitForm = async () => {
    try {
      const params = methods.getValues();
      const payload = {};
      let trxDate = new Date(params.transaction_date);
      payload.id = id;
      payload.implementing_partner_id = params.implementing_partner_id.value;
      payload.implementing_partner_name = params.implementing_partner_id.label;
      payload.implementing_partner_project_id =
        params.implementing_partner_project_name.value;
      payload.implementing_partner_project_name =
        params.implementing_partner_project_name.label;
      payload.desc = params.desc;
      payload.transaction_date = `${trxDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${(trxDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${trxDate.getFullYear()}`;
      payload.invoice_number = params.invoice_number;
      payload.primary_activity = {
        id: params.primary_activity.value,
        name: params.primary_activity.label,
      };
      payload.detail_activity = {
        id: params.detail_activity.value,
        name: params.detail_activity.label,
      };
      payload.currency = {
        id: "string",
        name: "string",
      };
      payload.required_payment = params.required_payment;
      payload.tax = {
        id: params.tax.value,
        name: params.tax.label,
        value: params.tax_value,
      };
      payload.billing_code = params.billing_code;
      payload.total_payment = params.total_payment;
      payload.account_number = params.account_number;
      payload.account_owner_name = params.account_owner_name;
      payload.bank = {
        id: params.bank_detail.id,
        name: params.bank,
      };
      payload.branch = params.branch;
      payload.status = params.status;

      let supporting_doc = [];

      for (let file of params.supporting_doc) {
        if (file instanceof File) {
          const fileFormData = new FormData();
          fileFormData.append("file", file);

          const resFile = await fileUpload(fileFormData);
          const resDataFile = await resFile.data.data;

          const payloadPerFile = {
            fileId: resDataFile.id,
            fileName: resDataFile.name,
            fileSize: resDataFile.size,
            mimeType: resDataFile.mimeType,
            path: resDataFile.url,
          };

          supporting_doc.push(payloadPerFile);
        } else {
          supporting_doc.push(file);
        }
      }
      payload.supporting_doc = supporting_doc;

      submitMutation.mutate(payload, {
        onSuccess: (res) => {
          navigate("/implementing-partner-payment");
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Breadcrumbs items={links} />
      <FormProvider {...methods}>
        <div className="flex flex-col gap-6 pt-8">
          <Card>
            <CardHeader>
              Tambah Pengajuan Pembayaran Implementing Partner
            </CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Mohon dibayarkan Kepada
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="implementing_partner_id"
                    placeholder="Nama Proyek"
                    isDisabled={implementingPartnerIsLoading}
                    options={implementingPartnerList.map((item) => ({
                      value: item._id,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setImplementingPartnerId(e.value);
                    }}
                  />
                </div>
                <div>
                  <label className="label font-semibold text-[14px] mb-2">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Untuk Proyek
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <CustomSelectForm
                    name="implementing_partner_project_name"
                    placeholder="Nama Proyek"
                    isDisabled={detailIsLoading}
                    options={implementingPartnerDetail?.projects.map(
                      (item) => ({
                        value: item._id,
                        label: item.project_name,
                        agreement_date: moment(
                          item.agreement_date,
                          "DD/MM/YYYY"
                        ).format("MM-DD-YYYY"),
                      })
                    )}
                    onChange={(e) => {
                      methods.setValue("transaction_date", e.agreement_date);
                    }}
                  />
                </div>

                <InputForm
                  textArea={true}
                  controllerName={"desc"}
                  className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                  label={"Uraian Pengajuan"}
                  placeholder={"Uraian Pengajuan"}
                  required={true}
                />

                <div className="grid grid-cols-3 gap-5">
                  <AtomDatePicker
                    controllerName={"transaction_date"}
                    label="Tanggal Perjanjian"
                    labelClass="p-0 pb-1"
                    wrapperClass={""}
                    format={"dd/MM/yyyy"}
                    disabled={true}
                    newDate={true}
                    required={true}
                    className={
                      "max-h-[41.5px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none"
                    }
                  />
                  <InputForm
                    controllerName={"invoice_number"}
                    className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                    label={"Nomor Invoice"}
                    placeholder={"Nomor Invoice"}
                    required={true}
                  />
                </div>

                <CardForm
                  label={
                    <div className="flex items-center w-full gap-3">
                      Data Pengajuan Pembayaran
                      <span className="font-semibold text-sm text-[#E34F13] bg-[#FFF4ED] rounded-full px-2 py-1">
                        Total Nilai Pembayaran :{" "}
                        {formatTotalCost(methods.watch("total_payment"))}
                      </span>
                    </div>
                  }
                  buttonCollapse={false}
                  childerForm={true}
                >
                  <div className="flex flex-col gap-5">
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>
                            Kegiatan Utama
                            <span className="text-[#F04438] text-[14px]">
                              *
                            </span>
                          </span>
                        </div>
                      </label>
                      <CustomSelectForm
                        name="primary_activity"
                        placeholder="Kegiatan Utama"
                        isDisabled={mainActivityIsLoading}
                        options={mainActivityList.map((item) => ({
                          value: item.id,
                          label: item.kegiatanUtama,
                          detail: item.detail,
                        }))}
                        onChange={(e) => setDetailActivity(e.detail)}
                      />
                    </div>
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>
                            Detail Kegiatan
                            <span className="text-[#F04438] text-[14px]">
                              *
                            </span>
                          </span>
                        </div>
                      </label>
                      <CustomSelectForm
                        name="detail_activity"
                        placeholder="Detail Kegiatan"
                        options={detailActivity.map((item) => ({
                          value: item.id,
                          label: item.detail,
                        }))}
                      />
                    </div>
                    <div>
                      <label className="label font-semibold text-[14px] mb-2">
                        <div className={`flex gap-[4px]`}>
                          <span className={`label-text`}>
                            Mata Anggaran
                            <span className="text-[#F04438] text-[14px]">
                              *
                            </span>
                          </span>
                        </div>
                      </label>
                      <CustomSelectForm
                        name="currency"
                        placeholder="Mata Anggaran"
                        disabled={budgetAccountIsLoading}
                        options={budgetAccountList.map((item) => ({
                          value: item.id,
                          label: item.nama,
                        }))}
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-3">
                      <InputCurrency
                        controllerName={"required_payment"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Pengajuan Pembayaran (Rp)"}
                        labelClass="p-0 pb-1"
                        placeholder={"Pengajuan Pembayaran (Rp)"}
                        required={true}
                      />
                      <div>
                        <label className="label font-semibold text-[14px] p-0 pb-1 mb-2">
                          <div className={`flex gap-[4px]`}>
                            <span className={`label-text`}>
                              Pajak
                              <span className="text-[#F04438] text-[14px]">
                                *
                              </span>
                            </span>
                          </div>
                        </label>
                        <CustomSelectForm
                          name="tax"
                          placeholder="Pajak"
                          disabled={taxTypeIsLoading}
                          options={taxTypeList.map((item) => ({
                            value: item.id,
                            label: item.nama,
                            tax: item.value,
                          }))}
                          // onChange={(e) => {
                          //   const rpayment =
                          //     methods.getValues("required_payment");
                          //   methods.setValue("tax_value", rpayment * e.tax);
                          // }}
                        />
                      </div>
                      <InputCurrency
                        controllerName={"tax_value"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Nilai Pajak (Rp)"}
                        labelClass="p-0 pb-1"
                        placeholder={"Nilai Pajak (Rp)"}
                        required={true}
                        disabled={true}
                      />
                      <InputForm
                        controllerName={"billing_code"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Kode Billing"}
                        placeholder={"Kode Billing"}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <InputCurrency
                        controllerName={"total_payment"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Total Pembayaran (Rp)"}
                        labelClass="p-0 pb-1"
                        placeholder={"Total Pembayaran (Rp)"}
                        required={true}
                        disabled={true}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <InputForm
                        controllerName={"account_number"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Norek"}
                        placeholder={"Norek"}
                        required={true}
                        disabled={true}
                      />
                      <InputForm
                        controllerName={"account_owner_name"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Nama Pemiik Rekening"}
                        placeholder={"Nama Pemiik Rekening"}
                        required={true}
                        disabled={true}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <InputForm
                        controllerName={"bank"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Bank"}
                        placeholder={"Bank"}
                        required={true}
                        disabled={true}
                      />
                      <InputForm
                        controllerName={"branch"}
                        className={`py-2 px-4 mt-2 border w-full rounded-md focus-within:border-primary-700`}
                        label={"Cabang"}
                        placeholder={"Cabang"}
                        required={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                </CardForm>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Dokumen Bukti Pendukung Pembayaran</CardHeader>
            <CardBody>
              <div className="flex flex-col gap-5">
                <div>
                  <label className="label font-semibold text-[14px]">
                    <div className={`flex gap-[4px]`}>
                      <span className={`label-text`}>
                        Dokumen Bukti Pendukung
                        <span className="text-[#F04438] text-[14px]">*</span>
                      </span>
                    </div>
                  </label>
                  <InputFormUpload
                    controllerName={"supporting_doc"}
                    maxSize={10240}
                    label=""
                    isMulti
                    hideSectionUpload={false}
                    listFiles={
                      detail?.supporting_doc
                        ? detail.supporting_doc.map((doc) => ({
                            ...doc,
                            preview: doc.path,
                          }))
                        : []
                    }
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="items-center justify-between block px-6 py-5 mt-4 mb-10 bg-white border border-gray-200 rounded-lg md:flex">
          <div>
            <Button
              type="button"
              className="px-8 text-gray-700 bg-white border-gray-300 hover:bg-gray-300"
              onClick={() => {
                navigate("/implementing-partner-payment");
              }}
            >
              Batal
            </Button>
          </div>

          <div className="items-center justify-between block gap-2 md:flex">
            <Button
              type="button"
              disabled={submitMutation.isLoading}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={() => {
                handleSubmitForm();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ImplementingPartnerPaymentEdit;
