import { DateInput } from "components";
import Input from "components/atoms/Input";
import _ from "lodash";
import React from "react";

import { Controller, useFormContext } from "react-hook-form";

const InputForm = ({
  controllerName,
  className,
  label,
  prefix = "",
  suffix = "",
  required = false,
  textArea = false,
  isDate = false,
  disabled = false,
  desc = false,
  info = "",
  onChangeInput,
  unit = null,
  customValue = null,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className={`form-control w-full`}>
            {label && (
              <label
                className="label p-0 font-semibold text-[14px]"
                htmlFor={_.kebabCase(label)}
              >
                <div className={`flex gap-[4px]`}>
                  <span className={`label-text`}>
                    {label}
                    {required && (
                      <span className="text-[#F04438] text-[14px]">*</span>
                    )}
                  </span>
                </div>
              </label>
            )}

            {desc && (
              <label className="font-semibold pt-0 pb-1">
                <div className={`flex`}>
                  <span className={`text-[#98A2B3] text-[12px]`}>{desc}</span>
                </div>
              </label>
            )}

            {textArea ? (
              <textarea
                onChange={onChange}
                id={_.kebabCase(label)}
                {...props}
                className={`focus:outline-none  border-[#D0D5DD] focus:border-primary-700 ${
                  className ? className : "input w-full max-w-xs border"
                } ${invalid ? "input-error" : "input-bordered"} ${
                  invalid ? "border-[#F04438]" : ""
                } ${
                  disabled
                    ? `cursor-not-allowed border-none bg-[#F2F2F2]`
                    : `cursor-pointer`
                }`}
                disabled={disabled}
                value={
                  customValue === "" || customValue === null
                    ? value
                    : customValue
                }
              />
            ) : isDate ? (
              <DateInput
                {...props}
                onChange={onChange}
                disabled={disabled}
                value={value}
                invalid={invalid}
              />
            ) : (
              <Input
                {...props}
                id={_.kebabCase(label)}
                prefix={prefix}
                suffix={suffix}
                unit={unit}
                onChange={(e) => {
                  onChange(e);
                  if (onChangeInput) {
                    onChangeInput(e);
                  }
                }}
                value={customValue === null ? value : customValue}
                className={
                  className
                    ? `${className} border-[#D0D5DD] ${
                        invalid ? "border-[#F04438]" : ""
                      }  ${
                        disabled
                          ? `cursor-not-allowed border-none bg-[#F2F2F2]`
                          : `cursor-pointer`
                      }`
                    : `input w-full max-w-xs border ${
                        invalid ? "input-error" : "input-bordered"
                      } ${invalid ? "border-[#F04438]" : ""}  ${
                        disabled
                          ? `cursor-not-allowed border-none bg-[#F2F2F2]`
                          : `cursor-pointer`
                      }`
                }
                disabled={disabled}
              />
            )}
            {invalid && (
              <label className="label" htmlFor={_.kebabCase(label)}>
                <div className="text-xs mt-2 label-text-alt text-error text-[#F04438]">
                  {error?.message}
                </div>
              </label>
            )}
            {info && (
              <label
                className="label font-semibold"
                htmlFor={_.kebabCase(label)}
              >
                <div className={`flex gap-[4px]`}>
                  <span className={`text-[#98A2B3] text-[12px]`}>{info}</span>
                </div>
              </label>
            )}
          </div>
        );
      }}
    />
  );
};

export default InputForm;
