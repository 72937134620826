import React, { useCallback, useEffect, useState } from "react";
import ProgressStepForm from "components/molecules/ProgressStepForm";
import Step1 from "../addInformation";
import Step2 from "../addBenefit";
import Step3 from "../addResultChain";
import Step4 from "../addSupportFile";
import axios from "axios";
import config from "app/config";
import { Link } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from "../../../context/InvestmentPlanContext";
import axiosInstance from "app/interceptors";

const AddInvestmentPlan = () => {
  const { idInvestment, idProposal, currentStep, type, fetchedData, setData } =
    useInvestmentPlanContext();
  const location = useLocation();
  const {
    typeForm,
    idInvestment: investmentFromState,
    idProposal: proposalFromState,
    currentStep: stepFromState,
  } = location.state || {};

  const fetchInvestment = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan?id=${investmentFromState}`
    );

    setData({
      dataInvestment: res.data?.data,
      idInvestment: investmentFromState,
      idProposal: proposalFromState,
      currentStep: stepFromState,
      type: typeForm,
      fetchedData: true,
    });
  };

  useEffect(() => {
    if (typeForm === "edit" && fetchedData == false) {
      fetchInvestment();
    }
  }, [typeForm, idInvestment]);

  const links = [
    {
      label: "Daftar Investmen Plan",
    },
    {
      label: "Penyusunan Investment Plan",
    },
    {
      label: "Ajukan Project",
    },
    {
      label: "Ajukan Project",
    },
  ];

  const stepForm = [
    "Informasi Umum",
    "Penerima Manfaat",
    "Result Chain",
    "Dokumen Pendukung",
  ];
  const step = Number(location.pathname.split("/")[3]);

  return (
    <div>
      {/* start Breadcrumb */}
      <div className="bg-transparent flex items-center flex-wrap flex-1">
        <ul className="flex items-center">
          <li className="inline-flex items-center">
            <Link to="/">
              <RiHome6Line className="text-gray-500 hover:text-primary-700" />
            </Link>
            <FiChevronRight className="text-gray-300 mx-2 w-5" />
          </li>
          <li className="inline-flex items-center">
            <Link
              to="/investment-plan"
              className="mb-0 text-gray-600 text-sm hover:text-primary-700"
            >
              {currentStep === 1 ? (
                <div>Daftar Investment Plan</div>
              ) : currentStep === 2 ? (
                <div>Penyusunan Investment Plan</div>
              ) : (
                <div>Ajukan Project</div>
              )}
              {/* {item.label} */}
            </Link>
            <p>&nbsp;{" > "}&nbsp;</p>
            <Link className="mb-0 text-gray-600 text-sm hover:text-primary-700">
              Add Investment Plan
              {/* {item.label} */}
            </Link>
            {/* <p className="mb-0 text-primary-700 text-sm bg-primary-50 px-2 py-1">{item.label}</p>
                {key < (links.length - 1) && <FiChevronRight className="text-gray-300 mx-2 w-5" />} */}
          </li>
        </ul>
      </div>
      {/* end BreadCrumb */}
      <ProgressStepForm items={stepForm} step={currentStep} />
      {currentStep === 1 ? (
        <Step1 />
      ) : currentStep === 2 ? (
        <Step2 />
      ) : currentStep === 3 ? (
        <Step3 />
      ) : (
        <Step4 />
      )}
    </div>
  );
};

const AddInvestmentPlanWithContext = () => {
  return (
    <InvestmentPlanProvider>
      <AddInvestmentPlan />
    </InvestmentPlanProvider>
  );
};

export default AddInvestmentPlanWithContext;
