/* eslint-disable react-hooks/exhaustive-deps */
import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
  AtomDatePicker,
  AtomButton,
  CardForm,
} from "components";
import { Select as AtomSelect } from "components";
import { InputForm } from "components";
import Select from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Navigate, useNavigate } from "react-router";
import {
  RiAddLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiDeleteBin6Line,
  RiSave3Line,
} from "react-icons/ri";
import axios from "axios";
import config from "app/config";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useAWPMutations } from "../hooks/useTimeWorkPlan";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const TimeWorkPlanForm = ({
  title = {},
  investmentPlanDetail = {},
  data = {},
  id = "",
}) => {
  const navigate = useNavigate();

  const [optionProposal, setOptionProposal] = useState([]);
  const [optionProvince, setOptionProvince] = useState([]);
  const [investName, setInvestmentName] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [awpList, setAwpList] = useState([]);
  const [awpDetailList, setAwpDetailList] = useState([]);
  const [awpActivityList, setAwpActivityList] = useState([]);
  const [startPeriod, setStartPeriod] = useState([]);
  const [endPeriod, setEndPeriod] = useState([]);
  const [totalFund, setTotalFund] = useState(0);
  const [prevAwp, setPrevAwp] = useState();
  const [isFetching, setIsFetching] = useState(true);

  let isEdit = !_.isEmpty(investmentPlanDetail);

  const location = useLocation();
  const currentPath = location.pathname;
  const { fetchProject, fetchAWP, fetchAWPActivity, fetchAWPDetail, submitMutation } =
    useAWPMutations();
  const userId = localStorage.getItem("userId");

  const validationSchema = useMemo(() => {
    return yup.object({
      work_plan_name: yup.string().required("Rencana Kerja wajib diisi"),
      period: yup
        .array()
        .of(yup.string(), yup.string())
        .required("Periode kegiatan wajib diisi"),
      project_name: yup.mixed().required("Proyek wajib diisi"),
      awp_project_name: yup.mixed().required("AWP wajib diisi"),
      awp_project_activity_name: yup
        .mixed()
        .required("Kegiatan utama wajib diisi"),
      awp_detail_activities: yup.array().of(
        yup.object().shape({
          awp_detail_activity_name: yup
            .mixed()
            .required("Detail kegiatan wajib diisi")
            .label("Detail kegiatan"),
          awp_fund: yup
            .string()
            .matches(
              /^\d{1,3}(.\d{3})*(\.\d+)?$/,
              "Hanya boleh diisi dengan angka"
            )
            .label("Dana di AWP"),
          requirement_fund: yup
            .string()
            .matches(
              /^\d{1,3}(.\d{3})*(\.\d+)?$/,
              "Hanya boleh diisi dengan angka"
            )
            .required("Kebutuhan Dana wajib diisi")
            .label("Kebutuhan Dana"),
        })
      ),
    });
  }, []);

  const defaultValues = {
    work_plan_name: null,
    period: null,
    project_name: null,
    awp_project_name: null,
    awp_project_activity_name: null,
    awp_detail_activities: [
      {
        requirement_fund: 0,
        awp_fund: 0,
        awp_detail_activity_name: null,
      },
    ],
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    getValues,
    control,
    register,
    formState: { errors, isValid },
  } = methods;

  const {
    fields: awpFields,
    append: appendAwpFields,
    remove: removeAwpFields,
  } = useFieldArray({
    control,
    name: `awp_detail_activities`,
  });

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  const mapOption = async (dataPromise, name) => {
    try {
      const data = await dataPromise;
      if (name === "Detail") {
        // data.forEach((obj, idx) => {
        //   setValue(`awp_detail_activities.${idx}.awp_detail_activity_name`, {
        //     value: obj._id,
        //     awp_id: obj.awp_id,
        //     awp_activity_id: obj.awp_activity_id,
        //     budget: obj.budget,
        //     execution: obj.execution,
        //     label: obj.name,
        //   });
        // });

        let temp = data?.map((obj) => ({
          value: obj._id,
          awp_id: obj.awp_id,
          awp_activity_id: obj.awp_activity_id,
          budget: obj.budget,
          execution: obj.execution,
          label: obj.name,
        }));
        setAwpDetailList(temp);
      } else if (name === "Activity") {
        let temp = data?.map((obj) => ({
          value: obj._id,
          awp_id: obj.awp_id,
          awp_output_id: obj.awp_output_id,
          awp_output_name: obj?.output?.name,
          procurement: obj.procurement,
          label: obj.name,
        }));

        setAwpActivityList(temp);
      } else if (name === "Project") {
        let temp = data?.map((obj) => ({
          value: obj._id,
          label: obj.name,
        }));

        setProjectList(temp);
      } else {
        let temp = data?.map((obj) => ({
          value: obj.id,
          investment_id: obj.investment_id,
          label: obj.project_name + ` (${obj.proposal_number})`,
        }));

        setAwpList(temp);
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshAWP = () => {
    let temp = watch(`project_name`);
    /** Refreshing awp project activity name and detail activity every change awp project activity name */
    setValue("awp_project_name", "");
    setValue("awp_project_activity_name", []);
    setValue("awp_detail_activities", [
      {
        requirement_fund: 0,
        awp_fund: 0,
        awp_detail_activity_name: "",
      },
    ]);

    if (temp?.label) {
      mapOption(fetchAWP({ name: temp?.label }), "AWP");
    }
  };

  const refreshActivity = () => {
    let temp = watch(`awp_project_name`);
    /** Refreshing awp project activity name and detail activity every change awp project activity name */
    setValue(`awp_project_activity_name`, "");
    setValue(`awp_detail_activities`, [
      {
        requirement_fund: 0,
        awp_fund: 0,
        awp_detail_activity_name: "",
      },
    ]);

    if (temp?.value) {
      mapOption(fetchAWPActivity({ awp_id: temp?.value }), "Activity");
    }
  };

  useEffect(() => {
    let temp = watch(`project_name`);
    if (_.isEmpty(data)) {
      if (temp) {
        refreshAWP();
      }
    } else {
      if (temp?.label !== data?.project_name) {
        refreshAWP();
      }
    }
  }, [watch(`project_name`)]);


  useEffect(() => {
    let temp = watch(`awp_project_name`);
    if (_.isEmpty(data)) {
      if (temp) {
        refreshActivity();
      }
    } else {
      if (temp?.label !== data?.awp_project_name) {
        refreshActivity();
      }
    }
  }, [watch(`awp_project_name`)]);

  useEffect(() => {
    let temp = watch(`awp_project_activity_name`);
    if (temp?.value) {
      mapOption(fetchAWPDetail({ activity_id: temp?.value }), "Detail");
    }
  }, [watch(`awp_project_activity_name`)]);

  const awpDetailActivityName = useWatch({
    name: "awp_detail_activities",
    control,
  });

  function formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  useEffect(() => {
    if (JSON.stringify(awpDetailActivityName) !== JSON.stringify(prevAwp)) {
      updateData();
    }
    setPrevAwp(awpDetailActivityName);
  }, [awpDetailActivityName]);

  const updateData = useCallback(() => {
    awpDetailActivityName?.forEach((field, index) => {
      const awpFund = field?.awp_detail_activity_name;
      if (awpFund?.budget) {
        setValue(
          `awp_detail_activities.${index}.awp_fund`,
          convertMoney(String(awpFund?.budget))
        );
      } else {
        setValue(
          `awp_detail_activities.${index}.awp_fund`,
          convertMoney(String(0))
        );
      }
    });
  }, [awpDetailActivityName, setValue]);

  useEffect(() => {
    // if (data?.total_requirement_fund === null) {

    /** Disable validation if requirement fund can't bigger than awp fund */
    // awpDetailActivityName?.forEach((field, index) => {
    //   const awpRequirementFund = field?.requirement_fund;
    //   const awpFund = field?.awp_fund;

    //   if (parseInt(awpRequirementFund) > awpFund) {
    //     setValue(`awp_detail_activities.${index}.requirement_fund`, awpFund);
    //   }
    // });

    let temp = 0;

    awpDetailActivityName?.forEach((field, index) => {
      let result =
        parseInt(temp) +
        (field?.requirement_fund
          ? parseInt(
            field?.requirement_fund
              ? field?.requirement_fund?.replace(/\D/g, "") ?? 0
              : 0
          )
          : 0);

      setValue("total_requirement_fund", parseInt(result));
      setTotalFund(result);
      temp = field?.requirement_fund
        ? parseInt(field?.requirement_fund?.replace(/\D/g, ""))
        : 0;
    });
    // }
  }, [awpDetailActivityName]);

  const searchByLabel = (dataArray, searchTerm) => {
    const filteredArray = dataArray.filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredArray;
  };

  const fetchData = async () => {
    await mapOption(fetchProject(), "Project");
  };

  const loadData = async () => {
    if (currentPath.includes("revision")) {
      await fetchData();
      // for (let key in data) {
      //   if (key === "start_date") {
      //     let temp = [new Date(data?.start_date), new Date(data?.end_date)];
      //     register("period");
      //     setValue("period", temp);
      //   } else if (key === "awp_detail_activities") {
      //     appendAwpFields({
      //       requirement_fund: 0,
      //       awp_fund: "",
      //       awp_detail_activity_name: "",
      //     });
      //   } else if (key === "awp_project_activity_name") {
      //     setValue(key, [
      //       { label: data[key], value: data[`awp_project_activity_id`] },
      //     ]);
      //   } else if (key === "awp_project_name") {
      //     setValue(key, [
      //       { label: data[key], value: data[`awp_project_id`] },
      //     ]);
      //   } else if (key === "total_requirement_fund") {
      //     setValue(key, data[key]);
      //     setTotalFund(data[key]);
      //   } else {
      //     setValue(key, data[key]);
      //   }
      // }
    } else {
      await fetchData();
    }
  };

  useEffect(() => {
    if (!_.isEmpty(data)) {
      methods.reset({
        ...data,
        awp_detail_activities: data?.awp_detail_activities.map(
          (detail, index) => ({
            ...detail,
            awp_detail_activity_name: {
              value: detail?.awp_detail_activity_id,
              label: detail?.awp_detail_activity_name,
            },
            awp_fund: convertMoney(String(detail?.awp_fund || "0")),
            requirement_fund: convertMoney(String(detail?.requirement_fund)),
          })
        ),
        project_name: {
          label: data["project_name"],
          value: data["project_id"],
        },
        awp_project_name: {
          label: data["awp_project_name"],
          value: data["awp_project_id"],
        },
        period: [new Date(data?.start_date), new Date(data?.end_date)],
        awp_project_activity_name: {
          label: data["awp_project_activity_name"],
          value: data["awp_project_activity_id"],
        },
        total_requirement_fund: data["total_requirement_fund"],
      });
      setTotalFund(data["total_requirement_fund"]);
    }
  }, [data]);

  useEffect(() => {
    methods.register("total_requirement_fund");

    loadData();
  }, []);

  // useEffect(() => {
  //   if (currentPath.includes("add")) {
  //     if (awpFields.length === 0) {
  //       appendAwpFields({
  //         requirement_fund: 0,
  //         awp_fund: "",
  //         awp_detail_activity_name: "",
  //       });
  //     }
  //   }
  // }, []);

  const onSave = (data) => {
    data.source = investName;
    data.currency = "USD";

    if (data.start_period === "" || data.start_period === startPeriod) {
      data.start_period = startPeriod;
    }

    if (data.end_period === "" || data.end_period === endPeriod) {
      data.end_period = endPeriod;
    }
    try {
      axios
        .post(
          `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/first-step`,
          data
        )
        .then((response) => {
          navigate("investment-plan");
        });
      // return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const consumeAPI = (datas) => {
    let params = {
      id: currentPath.includes("revision") ? id : null,
      user_id: userId,
      work_plan_name: datas?.work_plan_name
        ? datas?.work_plan_name
        : data?.work_plan_name,
      start_date: datas?.period[0]
        ? moment(datas?.period[0]).format("DD/MM/YYYY")
        : data?.start_date,
      end_date: datas?.period[1]
        ? moment(datas?.period[1]).format("DD/MM/YYYY")
        : data?.end_date,
      project_id: datas?.project_name?.value
        ? datas?.project_name?.value
        : data?.project_id,
      project_name: datas?.project_name?.label
        ? datas?.project_name?.label
        : data?.project_name,
      awp_output_id: datas?.awp_project_activity_name?.awp_output_id
        ?? data?.awp_output_id,
      awp_output_name: datas?.awp_project_activity_name?.awp_output_name
        ?? data?.awp_output_name,
      awp_project_id: datas?.awp_project_name?.value
        ? datas?.awp_project_name?.value
        : data?.awp_project_id,
      awp_project_name: datas?.awp_project_name?.label
        ? datas?.awp_project_name?.label
        : data?.awp_project_name,
      awp_project_activity_id: datas?.awp_project_activity_name?.value
        ? datas?.awp_project_activity_name?.value
        : data?.awp_project_activity_id,
      awp_project_activity_name: datas?.awp_project_activity_name?.label
        ? datas?.awp_project_activity_name?.label
        : data?.awp_project_activity_name,
      awp_detail_activities:
        datas?.awp_detail_activities?.length > 0
          ? datas?.awp_detail_activities?.map((field, index) => ({
            awp_fund: String(field?.awp_fund)?.replace(/\D/g, ""),
            awp_detail_activity_name: field?.awp_detail_activity_name?.label,
            awp_detail_activity_id: field?.awp_detail_activity_name?.value,
            requirement_fund: field?.requirement_fund?.replace(/\D/g, ""),
          }))
          : datas?.awp_detail_activities,
      total_requirement_fund: datas?.total_requirement_fund,
    };

    submitMutation.mutate(params);
  };

  const onSubmit = async () => {
    try {
      const formData = getValues();
      consumeAPI(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const awpDetailListFinal = useCallback((selected) => {
    const origin = awpDetailList;

    return origin.map((item) => {
      const isDisabled = selected.some(
        (selectedItem) => selectedItem.awp_detail_activity_name?.value === item.value
      );

      return {
        ...item,
        isDisabled,
      };
    });
  }, [awpDetailList, methods.watch('awp_detail_activities')]);

  if (submitMutation.isSuccess) {
    return <Navigate to="/rencana-kerja-waktu" replace />;
  }

  return (
    <FormProvider {...methods}>
      {!isFetching && (
        <>
          <div className="flex flex-col gap-4 mt-3">
            <Card>
              <CardHeader>
                <div>{title}</div>
              </CardHeader>
              <CardBody>
                <div className="flex flex-col gap-6">
                  <div className="space-y-[9px]">
                    <InputForm
                      controllerName={"work_plan_name"}
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={"Nama Rencana Kerja Waktu Tertentu"}
                      required
                    />
                  </div>
                  <div className="space-y-[9px]">
                    <AtomDatePicker
                      label="Periode Kegiatan"
                      controllerName={"period"}
                      className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                      name="period"
                      placeholder="DD/MM/YYYY - DD/MM/YYYY"
                      asText={false}
                      isRangeDatePicker={true}
                      required={true}
                    />
                  </div>

                  <div className="space-y-[9px]">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Pilih Proyek
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <AtomSelect
                      controllerName={`project_name`}
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={projectList ? projectList : []}
                      isDisabled={isEdit}
                    />
                  </div>
                  <div className="space-y-[9px]">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Pilih AWP
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <AtomSelect
                      controllerName={`awp_project_name`}
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={awpList ? awpList : []}
                      isDisabled={isEdit}
                    />
                  </div>
                  <div className="space-y-[9px]">
                    <label className="text-[#1D2939] font-semibold text-sm">
                      Kegiatan Utama
                      <span className="ml-1 text-error-500 text-sm">*</span>
                    </label>
                    <AtomSelect
                      controllerName={`awp_project_activity_name`}
                      className="text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                      options={awpActivityList}
                      isDisabled={isEdit}
                      value={{
                        value: methods.watch("awp_project_activity_name"),
                        label: methods.watch("awp_project_activity_name"),
                      }}
                    />
                  </div>

                  <div className="border-b" />
                  {awpFields.length > 0 &&
                    awpFields.map((data, index) => (
                      <>
                        <div className="flex gap-4 flex-row" key={data.id}>
                          <div className="flex-1 pt-2">
                            <AtomSelect
                              label="Detail Kegiatan"
                              required={true}
                              controllerName={`awp_detail_activities.${index}.awp_detail_activity_name`}
                              className="text-sm remove-input-txt-border-react-select"
                              options={awpDetailListFinal(watch("awp_detail_activities"))}
                              isDisabled={isEdit}
                              value={{
                                label: methods.watch(
                                  `awp_detail_activities.${index}.awp_detail_activity_name`
                                ),
                                value: methods.watch(
                                  `awp_detail_activities.${index}.awp_detail_activity_name`
                                ),
                              }}
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`awp_detail_activities.${index}.awp_fund`}
                              className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Dana di AWP"}
                              prefix="Rp."
                              disabled
                            />
                          </div>
                          <div className="flex-1 pt-2">
                            <InputForm
                              controllerName={`awp_detail_activities.${index}.requirement_fund`}
                              className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                              label={"Kebutuhan Dana"}
                              prefix="Rp."
                              onChangeInput={(e) => {
                                setValue(
                                  `awp_detail_activities.${index}.requirement_fund`,
                                  convertMoney(e.target.value)
                                );
                              }}
                            />
                          </div>
                          {index !== 0 ? (
                            <div className="flex items-start">
                              <button
                                className={`w-11 h-11 flex justify-center items-center p-3 ${index !== 0 ? "bg-[#D92D20]" : ""
                                  } text-white rounded-md self-center`}
                                onClick={() => removeAwpFields(index)}
                              >
                                <RiDeleteBin6Line />
                              </button>
                            </div>
                          ) : (
                            <div className={`w-11 h-11`} />
                          )}
                        </div>
                      </>
                    ))}
                  <div className="flex flex-row">
                    <div className="flex-1 pt-2"></div>
                    <div className="flex-1 pt-2"></div>
                    <div className="flex-1 pt-2 text-end">
                      <AtomButton
                        className="p-4 border rounded-lg"
                        label={
                          <div className="flex items-center gap-2 font-[14px]">
                            <RiAddLine />
                            Tambah Detail Kegiatan
                          </div>
                        }
                        onClick={() =>
                          appendAwpFields({
                            requirement_fund: 0,
                            awp_fund: null,
                            awp_detail_activity_name: null,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-1 pt-2"></div>
                    <div className="flex-1 pt-1"></div>
                    <div className="flex-1 pt-2 inline-flex">
                      <span className="self-center pr-3 font-semibold">
                        Total
                      </span>
                      <div className="bg-red-500 rounded py-3 px-3 w-full">
                        <span className="text-white">
                          Rp. {formatNumber(totalFund)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <div>
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
                onClick={() => navigate("/rencana-kerja-waktu/")}
              // onClick={() => {
              //   methods.trigger();
              //   console.log(errors, isValid);
              // }}
              >
                <RiArrowLeftLine />
                Kembali
              </Button>
            </div>
            <div className="block md:flex items-center justify-between gap-2">
              {/* <Button
                type="button"
                className="text-black border-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={methods.handleSubmit(onSave)}
              >
                <RiSave3Line className="text-black" />
                Simpan & Draft
              </Button> */}
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Submit
                <RiArrowRightLine className="text-white" />
              </Button>
            </div>
          </div>
        </>
      )}
    </FormProvider>
  );
};

export default TimeWorkPlanForm;
