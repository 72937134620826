import { Button } from "components";
import React, { useContext, useRef, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
import {
  RiUploadCloud2Line,
  RiFile2Line,
  RiDeleteBin6Line,
} from "react-icons/ri";
import { humanizeFileSize } from "../../../helpers";
import { useMutation } from "@tanstack/react-query";
import {
  fileUpload,
  getFile,
  getUrlFile,
} from "services/danaProgram/fileService";
import { createPortal } from "react-dom";
import { ConfirmationModal } from "components";
// import UploadDocModal from "../Modal/UploadDocModal";
import { template } from "lodash";
import { useEffect } from "react";
import { IoMdImage } from "react-icons/io";
import { FaRegFile } from "react-icons/fa";
import { BsEyeFill } from "react-icons/bs";
import { ErrorToast, SuccessToast, ToastContext } from "components/atoms/Toast";

const InputFormUpload = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  trigger,
  maxSize = 5 * 1024 * 1024,
  fileType = "PNG, JPG, PDF, Docx",
  buttonLabel,
  isMulti = false,
  onUploadMultiple,
  uploadFile,
  generateUrl,
  setUploadedUrls,
  setControllerTarget,
  onModalSubmit = () => {},
  listFiles = [],
  hideSectionUpload = false,
  labelClassname = null,
  disableDelete = false,
  acceptedFileTypes = [
    ".docx",
    ".doc",
    ".pdf",
    ".jpg",
    ".jpeg",
    ".png",
  ],
  ...props
}) => {
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);
  const refInput = useRef(controllerName);
  const dropRef = useRef(null);
  const [valueParams, setValueParams] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);

  const [files, setFiles] = useState([]);
  const { control, setValue, watch } = useFormContext();

  const triggerRef = () => {
    refInput.current.click();
  };

  const handleSelectImage = async (file, key) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      setPreviewImage(fileReader?.result);
    });
    fileReader.readAsDataURL(file);

    file["preview"] = URL.createObjectURL(file);

    const validation = handleCheckFileSize(file);
    if (!validation) return;

    if (key === "upload") {
      setFiles([file]);
      setValue(controllerName, file, { shouldValidate: true });
    }

    if (key === "multi") {
      setFiles((prev) => [...prev, file]);
      setValue(
        controllerName,
        [...watch(controllerName), file],
        { shouldValidate: true },
      );
    }
  };

  const maxFileSize = humanizeFileSize(maxSize, 0, "MB");

  const convertMaxFileSizeStringToNumber = (maxFileSize) => {
    const size = maxFileSize.split(" ");
    const number = Number(size[0]);
    const unit = size[1];
    if (unit === "KB") {
      return number * 1024;
    } else if (unit === "MB") {
      return number * 1024 * 1024;
    } else if (unit === "GB") {
      return number * 1024 * 1024 * 1024;
    } else if (unit === "TB") {
      return number * 1024 * 1024 * 1024 * 1024;
    }
  };

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCheckFileSize = (file) => {
    let isValid = false;
    const fileType = file.name.split(".").pop().toLowerCase();
    const fileSize = file.size;
    const maxSizeByte = convertMaxFileSizeStringToNumber(maxFileSize);
    if (!acceptedFileTypes.includes(`.${fileType}`)) {
      isValid = false;
      let message = `Tipe file tidak diterima. Tipe yang diterima adalah: ${acceptedFileTypes.join(", ")}`;
      showToastMessage(message);
      return setShowToast((prev) => ({ ...prev, error: true }));
    }
    if (fileSize > maxSizeByte) {
      isValid = false;
      let message = `Ukuran file melebihi batas maksimum ${handlingDisplayFileSize(maxSizeByte)}.`;
      showToastMessage(message);
      return setShowToast((prev) => ({ ...prev, error: true }));
    }
    isValid = true;
    // return 'File is valid.';
    return isValid;
  };

  useEffect(() => {
    if (listFiles.length > 0) setFiles(listFiles);
  }, [listFiles]);

  useEffect(() => {
    dropRef?.current?.addEventListener("dragover", handleDragOver);
    dropRef?.current?.addEventListener("drop", handleDrop);

    return () => {
      dropRef?.current?.removeEventListener("dragover", handleDragOver);
      dropRef?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);

  const onDeleteFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    return newFiles;
  };

  const handlingDisplayFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = (bytes / Math.pow(1024, i)).toFixed(2);
    return `${size} ${sizes[i]}`;
  };

  return (
    <>
      <Controller
        name={controllerName}
        control={control}
        defaultValue={""}
        render={({
          field: { onChange, value },
          fieldState: { invalid, error },
        }) => {
          if (Array.isArray(value)) {
            setImageUrl(value);
          }
          return (
            <>
              <div className="form-control w-full">
                {!hideSectionUpload && (
                  <>
                    <label
                      className={`${
                        labelClassname === null
                          ? "label font-semibold text-primary-700 text-[18px]"
                          : labelClassname
                      } `}
                    >
                      {label}
                      {required && <span className="text-[#F04438]">*</span>}
                    </label>
                    {isMulti && (
                      <label className="text-xs text-[#828282]">
                        *Bisa upload lebih dari satu
                      </label>
                    )}
                  </>
                )}
                {isMulti && (
                  <div
                    className={`grid ${
                      files.length > 0 ? "grid-cols-2" : "grid-cols-1"
                    } -mx-4 py-2 items-center h-full w-full justify-center`}
                  >
                    {files.map((data, key) => {
                      return (
                        <>
                          <div
                            key={"file-uploaded-" + key}
                            className={
                              "flex justify-between rounded border border-gray-300 p-4 m-4 h-auto"
                            }
                          >
                            <div className="w-10/12 flex justify-start">
                              <div
                                className={
                                  "bg-[#EEFFF4] p-1 rounded-full self-start"
                                }
                              >
                                <div
                                  className={"bg-[#D6FFE8] p-1 rounded-full"}
                                >
                                  <RiFile2Line
                                    className={"text-[#00BD52] text-3xl"}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  "flex flex-col text-left ml-5 w-full truncate"
                                }
                              >
                                <span
                                  className="text-sm font-medium truncate"
                                  title={data.name ?? data.fileName}
                                >
                                  {data.name ?? data.fileName}
                                </span>
                                <span className="text-sm">
                                  {/* {data.size
                                  ? (data.size / 1024 / 1024 ?? "0").toFixed(2)
                                  : (
                                      data.fileSize / 1024 / 1024 ?? "0"
                                    ).toFixed(2)}{" "}
                                MB */}

                                  {handlingDisplayFileSize(
                                    data.size ?? data.fileSize
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="w-2/12 flex items-center justify-end gap-2">
                              <a
                                href={data.path || data.preview}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <BsEyeFill
                                  size={18}
                                  fill="#667085"
                                  className="hover:fill-sky-600 cursor-pointer"
                                />
                              </a>
                              {!disableDelete && (
                                <RiDeleteBin6Line
                                  // onClick={() => {
                                  //   let newFiles = [];

                                  //   if (files.preview) {
                                  //     newFiles = files.filter(
                                  //       (file) =>
                                  //         file.preview !== files[key].preview
                                  //     );
                                  //   } else {
                                  //     newFiles = files.filter(
                                  //       (file) => file.path !== files[key].path
                                  //     );
                                  //   }

                                  //   setFiles(newFiles);
                                  //   onChange(newFiles);
                                  // }}
                                  onClick={() => {
                                    const newFiles = onDeleteFile(key);
                                    onChange(newFiles);
                                  }}
                                  size={18}
                                  fill="#667085"
                                  className="hover:fill-red-600 cursor-pointer"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
                {!isMulti ? (
                  <>
                    <input
                      type="file"
                      {...props}
                      ref={refInput}
                      onChange={({ target: { files } }) => {
                        handleSelectImage(files[0], "upload");
                      }}
                      value={valueParams}
                      className={"hidden"}
                    />
                    <div
                      ref={dropRef}
                      className="w-full"
                      onClick={triggerRef}
                      onDropCapture={({ dataTransfer: { files } }) => {
                        handleSelectImage(files[0], "upload");
                      }}
                    >
                      <div
                        className={`w-full mt-2 cursor-pointer bg-white text-center items-center border rounded-md h-64`}
                      >
                        <div
                          className={`flex flex-col items-center h-full justify-center ${
                            value || previewImage ? "p-2" : "py-2"
                          }`}
                        >
                          <>
                            {previewImage || (Array.isArray(value) ? value.length > 0 : value) ? (
                              <div
                                className={
                                  "w-full flex justify-between rounded border border-gray-300 p-4 flex-row m-4"
                                }
                              >
                                <div className="w-10/12 flex justify-start">
                                  <div
                                    className={
                                      "bg-[#EEFFF4] p-1 rounded-full self-start"
                                    }
                                  >
                                    <div
                                      className={
                                        "bg-[#D6FFE8] p-1 rounded-full"
                                      }
                                    >
                                      <RiFile2Line
                                        className={"text-[#00BD52] text-3xl"}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "flex flex-col text-left ml-5 truncate"
                                    }
                                  >
                                    <span className="truncate">
                                      {value.name}
                                    </span>
                                    <span>
                                      {(
                                        value.size / 1024 / 1024 || 0
                                      ).toFixed(2)} MB
                                    </span>
                                  </div>
                                </div>
                                <div className="w-2/12 flex items-center justify-end gap-2">
                                  <BsEyeFill
                                    onClick={() => handleClick(value.preview)}
                                    size={18}
                                    fill="#667085"
                                    className="hover:fill-sky-600 cursor-pointer"
                                  />
                                  {!disableDelete && (
                                    <RiDeleteBin6Line
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onChange();
                                        setPreviewImage(null);
                                      }}
                                      size={18}
                                      fill="#667085"
                                      className="hover:fill-red-600 cursor-pointer"
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`flex flex-col py-2 items-center h-full justify-center`}
                              >
                                <div className="flex flex-col justify-center items-center space-y-4">
                                  <div className="flex items-center justify-center bg-gray-100 text-lg h-9 w-9 rounded-full">
                                    <RiUploadCloud2Line className="text-gray-600" />
                                  </div>
                                  <div className="space-y-1">
                                    <div className="px-4 text-sm">
                                      <span className="font-semibold text-[#01A24A]">
                                        Klik untuk upload{" "}
                                      </span>
                                      <span className="font-normal">
                                        atau drag and drop
                                      </span>
                                    </div>
                                    <span className="text-xs">
                                      .docx, .doc, .pdf, .jpg, .jpeg, .png (max.{" "}
                                      {maxFileSize})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                    {invalid && (
                      <label className="label">
                        <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                          {error?.message}
                        </div>
                      </label>
                    )}
                  </>
                ) : (
                  !hideSectionUpload && (
                    <div
                      ref={dropRef}
                      className="w-full"
                      onClick={triggerRef}
                      onDropCapture={({ dataTransfer: { files } }) => {
                        if (files.length > 0) {
                          Object.values(files).forEach(element => {
                            handleSelectImage(element, "multi");
                          });
                        }
                      }}
                    >
                      <input
                        type="file"
                        {...props}
                        ref={refInput}
                        onChange={({ target: { files } }) => {
                          handleSelectImage(files[0], "multi");
                          // onChange([...value, files[0]]);
                        }}
                        value={valueParams}
                        className={"hidden"}
                        accept={acceptedFileTypes.join(", ")}
                      />
                      <div className="w-full mt-2 bg-white text-center items-center border rounded-md h-64 cursor-pointer">
                        <div
                          className={`flex flex-col py-2 items-center h-full justify-center`}
                        >
                          <div className="flex flex-col justify-center items-center space-y-4">
                            <div className="flex items-center justify-center bg-gray-100 text-lg h-9 w-9 rounded-full">
                              <RiUploadCloud2Line className="text-gray-600" />
                            </div>
                            <div className="space-y-1">
                              <div className="px-4 text-sm">
                                <span className="font-semibold text-[#01A24A]">
                                  Klik untuk upload{" "}
                                </span>
                                <span className="font-normal">
                                  atau drag and drop
                                </span>
                              </div>
                              <span className="text-xs">
                                .docx, .doc, .pdf, .jpg, .jpeg, .png (max.{" "}
                                {maxFileSize})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {invalid && (
                        <label className="label">
                          <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                            {error?.message}
                          </div>
                        </label>
                      )}
                    </div>
                  )
                )}
              </div>
            </>
          );
        }}
      />
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
    </>
  );
};

export default InputFormUpload;
