import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RiErrorWarningLine, RiEyeLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';

import { BasicList } from 'components/molecules/ListPage';

import { Button } from 'components';
import FilterVerification from './FilterVerification';
import useVerification from './hooks/useVerification';
import { BsDot } from 'react-icons/bs';
import { createPortal } from 'react-dom';
import { FormModal } from 'components/molecules/Modal';
import { ErrorToast, SuccessToast, ToastContext } from 'components/atoms/Toast';
import moment from 'moment';

const Verification = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  // const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [formModal, setFormModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const links = [
    {
      label: 'Daftar LEMTARA',
    },
  ];

  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const {
    verificationData,
    refetch,
    isPreviousData,
    onUnperform,
    confirmModal,
    setConfirmModal,
    onProcessUnperform,
  } = useVerification({
    search: keyword,
    status: typeFilter,
    page,
    limit,
    id: null,
  });

  useEffect(() => {
    refetch();
  }, [typeFilter, keyword, page, limit]);

  const methods = useForm({
    defaultValues: {
      format_number: '#0',
      impact: '',
      description: '',
      indicators: [],
    },
  });

  const result = verificationData?.data;

  // const result = useMemo(() => {
  //   return [
  //     {
  //       _id: "653912f9f502000b70c70725",
  //       user_id: "1d523a80-f2da-41bf-86e0-28baa5527758",
  //       general_profile: {
  //         id: "651da654560ecb48e6c87ee4",
  //         status: "NEED_APPROVAL"
  //       },
  //       organization_profile: {
  //         id: "6537db6ac29c5c4368f42195",
  //         status: "NEED_APPROVAL"
  //       },
  //       financial_profile: {
  //         id: "6537db96c29c5c4368f421a4",
  //         status: "NEED_APPROVAL"
  //       },
  //       resource: {
  //         id: "650af97cdd2aa2252424e5a2",
  //         status: "NEED_APPROVAL"
  //       },
  //       program_experience: {
  //         id: "6537dfd1c29c5c4368f421ab",
  //         status: "NEED_APPROVAL"
  //       },
  //       safe_guards: {
  //         id: "6537dfe1c29c5c4368f421b0",
  //         status: "NEED_APPROVAL"
  //       },
  //       total_score: 400,
  //       created_at: "2023-12-19T11:48:46.475Z",
  //       updated_at: "2023-12-19T11:48:46.475Z",
  //       contact_person: {
  //         name: "Person A",
  //         email: "pic_a@gmail.com",
  //         phone: "089"
  //       },
  //       intervention: [
  //         "Intervensi A"
  //       ],
  //       lemtara_name: "Lemtara 58",
  //       status: "REJECTED",
  //       est_date: "2023-08-30",
  //       regency: "Kota Bandung",
  //       total_funds: 15000000,
  //       lemtara_type: "Mencari Keuntungan"
  //     }
  //   ];
  // });

  const paginator = useMemo(() => {
    return {
      page: verificationData?.page,
      limit: verificationData?.limit,
      total: verificationData?.total,
      previous_pages: page > 1,
      next_pages: page < Math.round(Math.ceil(verificationData?.total / limit)),
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return '';
        }),
      []
    ),
    1000
  );

  const typeOptions = useMemo(() => {
    return [
      { value: 'NEED_APPROVAL', label: 'Perlu Verifikasi' },
      { value: 'VERIFIED', label: 'Terverifikasi' },
      { value: 'UNPERFORM', label: 'Unperform' },
      { value: 'REJECTED', label: 'Ditolak' },
    ];
  });

  // const thematicOptions = useMemo(() => {
  //   return [
  //     { value: 1, label: "Domestic" },
  //     { value: 2, label: "International" },
  //     { value: 3, label: "Bencana Alam" },
  //   ];
  // });

  const onChangeType = useCallback(
    (obj) =>
      setTypeFilter(() => {
        if (obj.value) return obj.value;
        return '';
      }),
    []
  );

  // const onChangeCategory = useCallback(
  //   (obj) => setCategoryFilter(obj.value),
  //   []
  // );

  const onCreate = () => {};
  const onEdit = (data) => {
    setFormModal(true);
    methods.reset(data);
  };

  const actionStatus = useMemo(() => {
    return [
      { id: 1, label: 'Verifikasi' },
      { id: 2, label: 'Unperform' },
    ];
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: 'no',
        title: 'No',
        dataIndex: 'no',
        className: 'overflow-hidden',
        columnClassName: 'w-[50px]',
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: 'lemtara_name',
        title: 'Nama Lemtara',
        dataIndex: 'lemtara_name',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ?? '-'}
          </div>
        ),
      },
      {
        id: 'status',
        title: 'Status',
        dataIndex: 'status',
        className: '',
        columnClassName:
          'w-[200px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[200px]">
            {value === 'NEED_APPROVAL' && (
              <div className="bg-[#FFFAEB] rounded-[25px] flex gap-[1px] items-center">
                <BsDot className="text-[40px] text-[#F79009]" />
                <div className="text-[#B54708] font-medium text-[12px]">
                  Perlu Verifikasi
                </div>
              </div>
            )}
            {value === 'VERIFIED' && (
              <div className="bg-[#F0F9FF] rounded-[25px] flex gap-[1px] items-center">
                <BsDot className="text-[40px] text-[#0BA5EC]" />
                <div className="text-[#026AA2] font-medium text-[12px]">
                  Terverifikasi
                </div>
              </div>
            )}
            {value === 'UNPERFORM' && (
              <div className="bg-[#F2F4F7] rounded-[25px] flex gap-[1px] items-center">
                <BsDot className="text-[40px] text-[#667085]" />
                <div className="text-[#344054] font-medium text-[12px]">
                  Unperform
                </div>
              </div>
            )}
            {value === 'REJECTED' && (
              <div className="bg-[#FEF3F2] rounded-[25px] flex gap-[1px] items-center">
                <BsDot className="text-[40px] text-[#F04438]" />
                <div className="text-[#B42318] font-medium text-[12px]">
                  Ditolak
                </div>
              </div>
            )}
          </div>
        ),
      },
      {
        id: 'lemtara_type',
        title: 'Jenis Lembaga',
        dataIndex: 'lemtara_type',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ? value : '-'}
          </div>
        ),
      },
      {
        id: 'contact_person',
        title: 'Narahubung',
        dataIndex: 'contact_person',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            <div className="flex flex-col">
              <div>{value?.name}</div>
              <div>{value?.email ?? '-'}</div>
              <div>{value?.phone}</div>
            </div>
          </div>
        ),
      },
      // {
      //   id: 'intervention',
      //   title: 'Fokus Isu/ Intervensi Lembaga',
      //   dataIndex: 'intervention',
      //   className: '',
      //   columnClassName:
      //     'overflow-hidden w-[200px] text-ellipsis overflow-hidden whitespace-pre-wrap',
      //   sortable: true,
      //   render: (value) => {
      //     return (
      //       <div className="overflow-hidden text-ellipsis w-full max-w-[150px] flex flex-col gap-[12px]">
      //         {value &&
      //           value.map((e) => (
      //             <div className="bg-[#EFF8FF] rounded-[25px] flex gap-[1px] items-center">
      //               <BsDot className="text-[40px] text-[#175CD3]" />
      //               <div className="text-[#175CD3] font-medium text-[12px]">
      //                 {e}
      //               </div>
      //             </div>
      //           ))}
      //       </div>
      //     );
      //   },
      // },
      {
        id: 'regency',
        title: 'Lokasi',
        dataIndex: 'regency',
        className: '',
        columnClassName:
          'w-[150px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
            {value ?? '-'}
          </div>
        ),
      },
      {
        id: 'total_funds',
        title: 'Kapasitas Pengelolaan Dana Hibah',
        dataIndex: 'total_funds',
        className: '',
        columnClassName:
          'w-[180px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
            Rp. {new Intl.NumberFormat('en-DE').format(value ?? 0)}
          </div>
        ),
      },
      {
        id: 'est_date',
        title: 'Tanggal Akta',
        dataIndex: 'est_date',
        className: '',
        columnClassName:
          'w-[110px] text-ellipsis overflow-hidden whitespace-pre-wrap',
        sortable: true,
        render: (value, data) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
              {value ? moment(new Date(value)).format('DD/MM/YYYY') : '-'}
            </div>
          );
        },
      },
      {
        id: 'action',
        title: 'Aksi',
        dataIndex: 'action',
        className: '',
        sortable: true,
        columnClassName:
          'w-[150px] text-center text-ellipsis overflow-hidden whitespace-pre-wrap',
        fixed: 'right',
        render: (value, data, index) => {
          const indexString =
            data?.status === 'NEED_APPROVAL' ? 1 : 'VERIFIED' ? 2 : 0;
          let keyAction = actionStatus.find(
            (element) => element.id === indexString
          );
          return (
            keyAction && (
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {['VERIFIED', 'UNPERFORM', 'REJECTED'].includes(
                  data?.status
                ) && (
                  <div className="space-x-3 flex w-full">
                    <RiEyeLine
                      className="text-gray-600 cursor-pointer flex-1"
                      size={20}
                      onClick={() => {
                        sessionStorage.setItem(`isLemtaraFormDisabled`, true);
                        navigate(`/verification/detail`);
                        sessionStorage.setItem(
                          'idData',
                          JSON.stringify({
                            general_profile: data?.general_profile?.id,
                            organization_profile:
                              data?.organization_profile?.id,
                            financial_profile: data?.financial_profile?.id,
                            resource: data?.resource?.id,
                            program_experience: data?.program_experience?.id,
                            safe_guards: data?.safe_guards?.id,
                            id: data?._id,
                          })
                        );
                      }}
                    />
                  </div>
                )}
                {!['UNPERFORM', 'REJECTED'].includes(data?.status) && (
                  <div className="space-x-3 justify-center flex w-full">
                    <Button
                      type="button"
                      className={`
                      text-white w-40
                      ${
                        keyAction.id == 1 &&
                        'border-[#00BD52] bg-[#00BD52] hover:bg-[#00BD52] hover:border-[#00BD52]'
                      }
                      ${
                        keyAction.id == 2 &&
                        'border-[#088AB2] bg-[#088AB2] hover:bg-[#088AB2] hover:border-[#088AB2]'
                      }
                      `}
                      onClick={async () => {
                        switch (keyAction.id) {
                          case 1:
                            navigate(`/verification/detail`);
                            sessionStorage.setItem(
                              `isLemtaraFormDisabled`,
                              false
                            );
                            sessionStorage.setItem(
                              'idData',
                              JSON.stringify({
                                general_profile: data?.general_profile?.id,
                                organization_profile:
                                  data?.organization_profile?.id,
                                financial_profile: data?.financial_profile?.id,
                                resource: data?.resource?.id,
                                program_experience:
                                  data?.program_experience?.id,
                                safe_guards: data?.safe_guards?.id,
                                id: data?._id,
                              })
                            );
                            break;
                          default:
                            await onUnperform(data._id);
                            break;
                        }
                      }}
                    >
                      {keyAction.label}
                    </Button>
                  </div>
                )}
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={`
                    text-white w-40 'border-[#F58A42] bg-[#F58A42] hover:bg-[#F58A42] hover:border-[#F58A42]'}
                    `}
                    onClick={() => {
                      sessionStorage.setItem('idVerification', data?._id);
                      navigate(`/verification/rasio-value`);
                    }}
                  >
                    Lihat Nilai Rasio
                  </Button>
                </div>
              </div>
            )
          );
        },
      },
    ];
  }, []);

  return (
    <div>
      {createPortal(
        <FormModal
          open={confirmModal}
          onClose={setConfirmModal}
          onSubmit={async () => await onProcessUnperform()}
          size={'w-2/8'}
          positiveConfirm={'Ya'}
          negativeConfirm={'Tidak'}
          withHeader={false}
        >
          <div>
            <div className="p-[5px] bg-[#FCE4CF] w-[50px] h-[50px] flex justify-center items-center rounded-full">
              <RiErrorWarningLine className={'text-[#DC6803] text-[38px]'} />
            </div>
          </div>
          <div className="text-lg font-[600] mt-[10px]">
            Nonaktifkan Lemtara
          </div>
          <div className="text-sm font-[400]">
            Apakah Anda yakin akan menonaktifkan LEMTARA ini?
          </div>
        </FormModal>,
        document.body
      )}
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={'Daftar Lemtara'}
        columns={tableColumns}
        dataSource={result}
        customFilter={
          <FilterVerification
            typeOptions={typeOptions}
            // categoryOptions={thematicOptions}
            onSearch={onSearch}
            onChangeType={onChangeType}
            // onChangeCategory={onChangeCategory}
          />
        }
        pagination={paginator}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(perPage) => setLimit(perPage)}
      />
    </div>
  );
};

export default Verification;
