import { useFormContext } from "react-hook-form";
import { BiMessageSquareCheck, BiSquareRounded } from "react-icons/bi";


const OptionCheckboxBadge = ({
  label = 'option',
  active = false,
  value = 1,
  name,
  disabled = false,
  ...props
}) => {
  const { setValue, watch, getValues } = useFormContext();
  return (
    <div
      className={`${!disabled ? `cursor-pointer` : `cursor-not-allowed`} flex items-center border-[2px] ${active ? `border-[#00BD52] bg-[#EEFFF4]`: `border-[#D0D5DD]`} rounded-[12px] gap-[8px] p-4 w-full`}
      onClick={() => {
        if (!disabled){
          if(active){
            setValue(name, watch(`${name}`).filter((e) => (typeof e === 'object' ? e.option_title : e) !== value))
          } else {
            const newArr = [...watch(`${name}`), value]
            setValue(name, newArr)
          }
        }
      }}
      {...props}
    >
      {active ? <BiMessageSquareCheck className="text-[20px] text-[#00BD52]" /> : <BiSquareRounded className="text-[20px]"/>}
      <div className="text-[16px] font-[600]">{label}</div>
    </div>
  )
}

export default OptionCheckboxBadge