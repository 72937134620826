import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { fileUpload } from 'services/danaProgram/fileService';
import { MENUS_NEED_APPROVAL } from './constant';

import ConfirmReject from './ConfirmReject';
import ConfirmApprove from './ConfirmApprove';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const credentials = yup.object().shape({
  approve_level: yup.number().required(),
  documents: yup.array().min(1, "Dokumen pendukung wajib diisi"),
});

const rejectionSchema = yup.object().shape({
  reason: yup.string().required('Alasan wajib diisi'),
  credentials,
});

const approvalSchema = yup.object().shape({
  credentials,
});

const Actions = ({
  module = 'rencana-penarikan-dana',
  id = '',
  status = '',
  maxDocSize,
}) => {
  const navigate = useNavigate();

  const userLoggedIn = useSelector((state) => state.auth?.user ?? {});
  const [formType, setFormType] = useState('');

  const isUserDirector = userLoggedIn?.Jabatan === 'Direktur Penyaluran Dana';
  const userApproveLevel = !isUserDirector ? 1 : 2;

  const currentMenuApproval = MENUS_NEED_APPROVAL.find(
    (menu) => menu.module === module
  );

  const queryClient = useQueryClient();
  const queryKey = `${currentMenuApproval.key}-approver-eligibility`;

  const [isApproverVerified, setIsApproverVerified] = useState(false);

  const formApproval = useForm({
    resolver: yupResolver(formType === "APPROVE" ? approvalSchema : rejectionSchema),
    defaultValues: {
      id,
      status: 'APPROVED',
      credentials: {
        approve_level: userApproveLevel,
        documents: [],
      },
      reason: '',
    },
  });

  const { isFetching: loadingVerifyApproverL1, refetch: verifyApproverL1 } =
    useQuery([queryKey, id], currentMenuApproval.queryVerify, {
      enabled: false,
    });

  const submitApproval = useMutation({
    mutationFn: (params) => currentMenuApproval.querySubmit(params),
  });

  const uploadDocuments = async () => {
    const documentsTemporary = formApproval.getValues('credentials.documents');

    if (documentsTemporary.length < 1) return [];

    let documentsUploaded = [];
    for (let file of documentsTemporary) {
      const fileFormData = new FormData();
      fileFormData.append('file', file);

      const resFile = await fileUpload(fileFormData);
      const resFileData = resFile.data.data;

      documentsUploaded.push({
        fileId: resFileData.id,
        fileName: resFileData.name,
        fileSize: resFileData.size,
        mimeType: resFileData.mimeType,
        path: resFileData.url,
      });
    }

    return documentsUploaded;
  };

  const handleSubmit = async (status) => {
    try {
      const payload = formApproval.getValues();
      const documentsUploaded = await uploadDocuments();

      submitApproval.mutate({
        ...payload,
        credentials: {
          ...payload.credentials,
          documents: documentsUploaded,
        },
        status,
      });

      const moduleName = currentMenuApproval.module.replace(/-/g, ' ');

      enqueueSnackbar(`${moduleName} ${status}`, { variant: 'success' });

      navigate(currentMenuApproval.urlIndex);
    } catch (error) {
      console.error(error);
    }
  };

  // verifying approver
  useEffect(() => {
    // verify approver level 1
    if (status === 'NEED_APPROVAL' && !isUserDirector) {
      console.log('masuknya kemana sih? ke level 1?');
      verifyApproverL1()
        .then(({ data }) => {
          setIsApproverVerified(data?.data?.message === 'Success');
        })
        .catch(() => setIsApproverVerified(false));
    } else if (status === 'NEED_APPROVAL_DIRECTOR' && isUserDirector) {
      console.log('masuknya kemana sih? ke level 2?');
      // verify approver level 2
      setIsApproverVerified(true);
    } else {
      verifyApproverL1()
        .then(({ data }) => {
          // setIsApproverVerified(data?.data?.message === 'Success');
        })
        .catch(() => setIsApproverVerified(false));
      setIsApproverVerified(false);
    }

    return () => {
      queryClient.invalidateQueries(queryKey);

      setIsApproverVerified(false);
    };
  }, [isUserDirector, id, status, queryClient, queryKey, verifyApproverL1]);

  return (
    <FormProvider {...formApproval}>
      <div className="flex-1 block md:flex items-center justify-between gap-2">
        <ConfirmReject
          onClick={(type) => setFormType(type)}
          disabled={loadingVerifyApproverL1 || !isApproverVerified}
          onConfirm={formApproval.handleSubmit(() => handleSubmit('REJECTED'))}
          maxDocSize={maxDocSize}
        />
      </div>

      <div className=" flex-1 block md:flex items-center justify-between gap-2">
        <ConfirmApprove
          onClick={(type) => setFormType(type)}
          disabled={loadingVerifyApproverL1 || !isApproverVerified}
          onConfirm={formApproval.handleSubmit(() => handleSubmit('APPROVED'))}
          maxDocSize={maxDocSize}
        />
      </div>
    </FormProvider>
  );
};

export default Actions;
