import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Input,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import _ from "lodash";
import config from "app/config";
import { UseAnnualWorkPlanContext } from "../../../context/AnnualWorkPlanContext";
import Select from "react-select";
import { formattedValue } from "helpers";
import axiosInstance from "app/interceptors";
import { getListBudgetAccount } from "services/masterData/budgetAccount";
import { ConfirmationModal } from "components/molecules/Modal";
import { FaTrashAlt } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array, number } from "yup";
import { useQuery } from "@tanstack/react-query";

const validationSchema = object().shape({
  total_funding: string().required("Total Funding wajib diisi"),
  budgeting: array().of(
    object().shape({
      awp_activity_detail_id: string().required("AWP Activity Detail ID wajib diisi"),
      budget_account: array().min(1, "Budget Account wajib diisi").of(
        object().shape({
          value: string().required("Value wajib diisi"),
          label: string().required("Label wajib diisi"),
        })
      ),
      items: array().min(1, "Item/Konsultan/Person wajib diisi").of(
        object().shape({
          item_name: string().required("Item Name wajib diisi"),
          quantity: number().required("Quantity wajib diisi"),
          unit: string().required("Unit wajib diisi"),
          volume: string().required("Volume wajib diisi"),
          unit_price: string().required("Unit Price wajib diisi"),
          sub_total: string().required("Sub Total wajib diisi"),
        })
      ),
    })
  ),
});

const AddAWPBudget = () => {
  const {
    idAwp,
    setData,
    setCurrentStep,
    currentStep,
    dataAnnualWorkPlan,
    type,
  } = UseAnnualWorkPlanContext();
  // const idAwp = idInvestment;

  const [detailActivityIds, setDetailActivityIds] = useState([]);
  const [detailAWP, setDetailAWP] = useState(null);
  const [confirmationModal, setConfirmModal] = useState(false);

  const navigate = useNavigate();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: "",
      total_funding: 0,
      budgeting: [],
    },
  });
  const budgetList = methods.watch("budgeting");
  const { data: budgetAccountList } = useQuery({
    queryKey: ["application-get-detail-activity"],
    enabled: detailActivityIds.length > 0,
    queryFn: async () => {
      const data = {};
      const promises = await Promise.all(
        detailActivityIds.map(async (id) => await getListBudgetAccount({ id }))
      );

      promises.forEach((el) => {
        data[el.config.params?.id] = el?.data?.data?.map((el) => ({
          value: el?.id,
          label: el?.nama,
        }));
      });

      return data;
    },
  });

  useEffect(() => {
    idAwp && fetchDetailAWP();
  }, [idAwp]);

  useEffect(() => {
    if (!_.isEmpty(detailAWP) && type !== "show") {
      const { output } = detailAWP;
      let arr = [];
      let detailActivityIdsTmp = [...detailActivityIds];

      _.map(output, (output) => {
        _.map(output.activity, (activity) => {
          _.map(activity.detail, (detail) => {
            if (!detailActivityIdsTmp.includes(detail?.detail_activity_id)) {
              detailActivityIdsTmp.push(detail?.detail_activity_id);
            }

            arr.push({
              awp_activity_detail_id: detail._id,
              detail: detail.name,
              budget_account: detail.budget_account,
              detail_activity_id: detail?.detail_activity_id,
              output: output.name,
              activity: activity.name,
              plan_time: detail.execution,
              items: detail?.budget?.map((item) => ({
                activity_budget_id: item?._id,
                item_name: item.item_name,
                quantity: item.quantity,
                unit: item.unit,
                volume: item.volume,
                unit_price: item?.unit_price
                  ? convertMoney(item?.unit_price)
                  : null,
                sub_total: item?.sub_total
                  ? formattedValue(item?.sub_total)
                  : null,
              })),
              isError: false,
              message: "",
            });
          });
        });
      });

      methods.reset({
        id: idAwp,
        total_funding: 0,
        budgeting: arr,
      });
      setDetailActivityIds(detailActivityIdsTmp);
    }
  }, [detailAWP, type]);

  useEffect(() => {
    if (!_.isEmpty(dataAnnualWorkPlan)) {
      const { output } = dataAnnualWorkPlan;
      let arr = [];
      let detailActivityIdsTmp = [...detailActivityIds];

      _.map(output, (output) => {
        _.map(output.activity, (activity) => {
          _.map(activity.detail, (detail) => {
            if (!detailActivityIdsTmp.includes(detail?.detail_activity_id)) {
              detailActivityIdsTmp.push(detail?.detail_activity_id);
            }

            arr.push({
              awp_activity_detail_id: detail._id,
              detail: detail.name,
              budget_account: detail.budget_account,
              detail_activity_id: detail?.detail_activity_id,
              output: output.name,
              activity: activity.name,
              plan_time: detail.execution,
              items: detail.budget.map((item) => ({
                item_name: item.item_name,
                quantity: item.quantity,
                unit: item.unit,
                volume: item.volume,
                unit_price: item?.unit_price
                  ? convertMoney(item?.unit_price)
                  : null,
                sub_total: item?.sub_total
                  ? formattedValue(item?.sub_total)
                  : null,
              })),
              isError: false,
              message: "",
            });
          });
        });
      });

      methods.setValue("budgeting", arr);
      setDetailActivityIds(detailActivityIdsTmp);
    }
  }, [dataAnnualWorkPlan]);

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
    let modifValue = "";
    let count = 1;
    if (oriValue !== "" && oriValue.length > 3) {
      const splitValue = oriValue.split("");
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }

    return modifValue;
  };

  const addItemHandler = (key) => {
    const temp = [...budgetList];
    temp[key].items.push({
      item_name: "",
      quantity: null,
      unit: "",
      volume: null,
      unit_price: null,
      sub_total: null,
      uid: _.uniqueId()
    });

    methods.setValue("budgeting", temp);
    methods.trigger(`budgeting[${key}].items`).then(() => {
      methods.clearErrors(`budgeting[${key}].items`);
    });
  };

  const onChangeItem = (e, detailKey, itemkey) => {
    const { name, value } = e.target;
    const temp = [...budgetList];

    if (["item_name", "unit"].includes(name)) {
      temp[detailKey].items[itemkey][name] = value;
    }

    if (name === "unit_price") {
      const tempValue = value.replace(/\D/g, "");
      temp[detailKey].items[itemkey].unit_price = convertMoney(tempValue);

      temp[detailKey].items[itemkey].sub_total =
        value.replace(/\D/g, "") *
        temp[detailKey].items[itemkey].quantity *
        parseInt(temp[detailKey].items[itemkey].volume);
    }

    if (name === "quantity") {
      temp[detailKey].items[itemkey].quantity = value.replace(/\D/g, "");
      temp[detailKey].items[itemkey].sub_total =
        value *
        (temp[detailKey].items[itemkey].unit_price?.replace(/\D/g, "") *
          parseInt(temp[detailKey].items[itemkey].volume));
    }

    if (name === "volume") {
      temp[detailKey].items[itemkey].volume = value.replace(/\D/g, "");
      temp[detailKey].items[itemkey].sub_total =
        value *
        temp[detailKey].items[itemkey].unit_price?.replace(/\D/g, "") *
        parseInt(temp[detailKey].items[itemkey].quantity);
    }

    methods.setValue("budgeting", temp);
    methods.trigger(`budgeting[${detailKey}].items[${itemkey}].${name}`);
  };

  const onChangeBudgetHandler = (e, key) => {
    let temp = [...budgetList];
    temp[key].budget_account = e;

    methods.setValue("budgeting", temp);
    methods.trigger(`budgeting[${key}].budget_account`);
  };

  const fetchDetailAWP = async () => {
    const response = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${idAwp}`
    );
    const data = await response.data.data;
    setDetailAWP(data);
  };

  const totalFunding = useMemo(() => {
    let total = null;

    budgetList?.forEach((obj) => {
      obj?.items?.forEach((item) => {
        total += _.isNumber(item?.sub_total || 0)
          ? item?.sub_total || 0
          : parseInt(item?.sub_total?.replace(/\D/g, ""));
      });
    });

    total = !_.isNaN(total) ? formattedValue(total) : formattedValue(0);

    methods.setValue("total_funding", total);

    return total;
  }, [budgetList]);

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmModal(!confirmationModal)}
        onSubmit={() => onSubmit(methods.getValues(), true)}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  const calculateSubActivity = (items) => {
    let total = 0;

    if (items.length > 1) {
      total = items.map((value) => {
        if (typeof value?.sub_total === "number") return value?.sub_total;

        return parseInt(value?.sub_total?.replace(/\D/g, ""));
      }).reduce((a, b) => a + (b || 0));
    } else {
      total = items.length === 1
        ? typeof items[0]?.sub_total === "number"
          ? items[0]?.sub_total
          : parseInt(items[0]?.sub_total?.replace(/\D/g, ""))
        : 0;
    }

    return new Intl.NumberFormat("en-DE").format(total);
  }

  const calculateSubActivityItems = (items) => {
    let detailsBudget = 0;

    if (items.length > 1) {
      detailsBudget = items.map((value) => {
        if (typeof value?.sub_total === "number") return value?.sub_total;

        return parseInt(value?.sub_total?.replace(/\D/g, ""));
      }).reduce((a, b) => a + (b || 0), 0);
    } else {
      detailsBudget = items.length === 1
        ? typeof items[0].sub_total === "number"
          ? items[0].sub_total
          : parseInt(items[0].sub_total?.replace(/\D/g, ""))
        : 0;
    }

    return new Intl.NumberFormat("en-DE").format(detailsBudget);
  };

  const deleteItemHandler = (detailKey, itemKey) => {
    let temp = [...budgetList];
    let arr = temp[detailKey].items.filter((_, i) => i !== itemKey);

    temp[detailKey].items = arr;

    methods.setValue("budgeting", temp);
    methods.trigger(`budgeting[${detailKey}].items`);
  };

  const onSubmit = async (data, is_save_draft = false) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "5" });
    } else {
      try {
        const getPayloadBudgeting = _.map(data.budgeting, (budget) => ({
          awp_activity_detail_id: budget.awp_activity_detail_id,
          budget_account: budget.budget_account,
          items: budget.items.map((item) => ({
            activity_budget_id: item?.activity_budget_id,
            item_name: item.item_name,
            quantity: Number(item.quantity),
            unit: item.unit,
            volume: item.volume,
            unit_price: Number(item.unit_price?.replace(/\D/g, "")),
            sub_total: _.isNumber(item?.sub_total)
              ? item?.sub_total
              : Number(item?.sub_total?.replace(/\D/g, "")),
          })),
        }));

        const payload = {
          id: idAwp,
          total_funding: totalFunding,
          budgeting: getPayloadBudgeting,
        };

        const response = await axiosInstance.post(
          `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/fourth-step`,
          payload
        );

        if (response.data.code === 200) {
          if(is_save_draft) {
            setConfirmModal(false);
            // navigate('/annual-work-plan')
          } else {
            setCurrentStep({ currentStep: "5" });

          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="flex flex-col gap-8">
      {/* Card Content */}
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader>
              <div className="flex gap-2 items-center">
                <p>Anggaran Biaya</p>
                <span className="bg-[#FFFAEB] p-1 font-medium text-xs text-[#B54708]">
                  {totalFunding}
                </span>
              </div>
            </CardHeader>
            <CardBody>
              <div className="space-y-8">
                {/** Start of budget list */}
                {budgetList?.map((detail, detailKey) => (
                  <CardForm
                    key={"detail-key-" + detail?.detail_activity_id}
                    label={
                      <div className="flex gap-2 items-center">
                        <p>
                          Detail/Sub Kegiatan {detailKey + 1} - {detail?.detail}
                        </p>
                        <span className="bg-[#ECFDF3] p-1 font-medium text-xs text-[#027A48] rounded">
                          Rp.{calculateSubActivity(detail?.items)}
                        </span>
                      </div>
                    }
                    buttonCollapse
                  >
                    <div className="flex flex-col w-full gap-5">
                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Budget Account
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Select
                          className="text-sm"
                          isMulti
                          options={budgetAccountList?.[detail?.detail_activity_id] ?? []}
                          value={detail?.budget_account}
                          onChange={(e) => onChangeBudgetHandler(e, detailKey)}
                          isDisabled={type === "show"}
                        />
                        <p className="text-red-500 text-xs">
                          {methods.formState.errors.budgeting?.[detailKey]?.budget_account?.message}
                        </p>
                      </div>

                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Output
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Input
                          className="w-full text-sm"
                          value={detail?.output}
                          placeholder=""
                          disabled
                        />
                      </div>

                      <div className="space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Kegiatan Utama
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>
                        <Input
                          className="w-full text-sm"
                          value={detail?.activity}
                          placeholder=""
                          disabled
                        />
                      </div>

                      {_.map(detail?.plan_time, (plan, planKey) => (
                        <div className="flex w-full gap-6">
                          <div className="flex flex-col gap-6 w-[50%]">
                            <label className="text-[#1D2939] font-semibold text-sm">
                              Rencana Waktu Pelaksanaan
                              <span className="ml-1 text-error-500 text-sm">
                                *
                              </span>
                            </label>
                            <Input
                              className={
                                "max-h-[38px] mt-0 rounded-md border-[#667085] border-solid text-sm outline-none focus:outline-none w-full"
                              }
                              type={"month"}
                              value={plan.execution_date}
                              disabled
                            />
                          </div>

                          <div className="flex-1 space-y-5">
                            <label className="text-[#1D2939] font-semibold text-sm">
                              Penanggung Jawab
                              <span className="ml-1 text-error-500 text-sm">
                                *
                              </span>
                            </label>

                            <Input
                              className="w-full text-sm"
                              value={plan.contact_person.label}
                              placeholder=""
                              disabled
                            />
                          </div>
                        </div>
                      ))}

                      {/* Information Bar */}
                      <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
                        <FiAlertTriangle color="#BE600E" size={20} />
                        <span className="text-[#8F480A] font-semibold text-sm">
                          Pada tabel dibawah, Anda dapat menambahkan
                          item/konsultan/person yang dibutuhkan untuk detail
                          kegiatan yang diatas
                        </span>
                      </div>

                      <Card>
                        <CardForm
                          label={
                            <div className="flex gap-2 item-center">
                              <p>Item/Konsultan/Person</p>
                              <span className="bg-[#ECFDF3] p-1 font-medium text-xs text-[#027A48] rounded">
                                Rp. {calculateSubActivityItems(detail?.items)}
                              </span>
                            </div>
                          }
                        >
                          <div className="border border-solid border-gray-200 rounded-tl-2xl rounded-tr-2xl">
                            <div className="overflow-x-auto">
                              <table className="table table-striped min-w-full">
                                <thead>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tl-2xl">
                                    No
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Nama Item
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Qty
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Satuan/Unit
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Volume
                                  </th>
                                  <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                                    Harga Satuan
                                  </th>
                                  <th className={`bg-primary-800 text-white text-sm font-semibold capitalize ${detail?.items?.length === 1 ? "rounded-tr-2xl" : ""}`}>
                                    Sub Total
                                  </th>
                                  {detail?.items?.length > 1 && (
                                    <th
                                      className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tr-2xl"
                                    />
                                  )}
                                </thead>
                                <tbody>
                                  {_.map(detail?.items, (item, itemKey) => (
                                    <tr key={`item-${item?.uid}`}>
                                      <td>{itemKey + 1}</td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="item_name"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.item_name}
                                          value={item.item_name}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="quantity"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.quantity}
                                          value={item.quantity}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="unit"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.unit}
                                          value={item.unit}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="volume"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.volume}
                                          value={item.volume}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="unit_price"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.unit_price}
                                          value={item.unit_price}
                                          onChange={(e) =>
                                            onChangeItem(e, detailKey, itemKey)
                                          }
                                          disabled={type === "show"}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          className="w-full text-sm"
                                          name="sub_total"
                                          error={methods.formState.errors.budgeting?.[detailKey]?.items?.[itemKey]?.sub_total}
                                          value={
                                            _.isNumber(item.sub_total)
                                              ? formattedValue(item.sub_total || 0)
                                              : item.sub_total
                                          }
                                          disabled
                                        />
                                      </td>
                                      {itemKey !== 0 && (
                                        <td className="py-0 pl-0">
                                          <Button
                                            className="bg-[#D92D20] text-white"
                                            startIcon={<FaTrashAlt />}
                                            onClick={() => deleteItemHandler(detailKey, itemKey, item)}
                                            disabled={type === "show"}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                  <tr>
                                    <td colSpan={8} align="right">
                                      {type !== "show" && (
                                        <div className="flex justify-between items-center">
                                          <p className="text-red-500 text-xs">
                                            {methods.formState.errors.budgeting?.[detailKey]?.items?.message}
                                          </p>
                                          <Button
                                            startIcon={<BiPlus size={15} />}
                                            className="bg-white border-[#D0D5DD]"
                                            onClick={() =>
                                              addItemHandler(detailKey)
                                            }
                                          >
                                            Item/Konsultan/Person
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardForm>
                      </Card>
                    </div>
                  </CardForm>
                ))}
                {/** End of budget card */}

                <Card>
                  <CardBody>
                    <div className="flex gap-6">
                      <div className="flex-1 space-y-[9px]">
                        <label className="text-[#1D2939] font-semibold text-sm">
                          Total Biaya Kegiatan Rupiah
                          <span className="ml-1 text-error-500 text-sm">*</span>
                        </label>

                        <Input
                          className="w-full text-sm"
                          value={totalFunding}
                          disabled
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>
      </FormProvider>

      {/* Button */}
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: "3" })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          {type !== "show" && (
            <Button type="button" onClick={() => setConfirmModal(true)}>
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit((data) => onSubmit(data))}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default AddAWPBudget;
