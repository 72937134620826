import React, { useMemo, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import moment from "moment";

import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";
import { Button } from "components";
import { SuccessToast, ErrorToast, ToastContext } from "components/atoms/Toast";
import { BasicList } from "components/molecules/ListPage";

import { formatCurrency } from "./helpers";
import useSubmissionWithdrawalPlan from "./hooks/useTimeWorkPlan";
import ListFilter from "./components/ListFilter";

const SubmissionWithdrawalFund = () => {
  const navigate = useNavigate();

  const {
    showToast,
    setShowToast,
    initialShowToast,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortMethod, setSortMethod] = useState("desc");

  const {
    withdrawalFundData,
    paginationtimeWorkPlanData: paginationWithdrawalFundData,
    refetch,
  } = useSubmissionWithdrawalPlan({
    params: {
      searchKey: nameFilter,
      status: statusFilter,
      start_date: "",
      end_date: "",
      page: page,
      limit: rowPage,
      orderMethod: sortMethod,
      orderBy: sortBy,
    },
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(rowPage) - parseInt(rowPage) + index + 1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },

      {
        id: "period",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        className: "overflow-hidden text-center",
        sortable: true,
        fixed: "center",
        columnClassName: "w-[calc(100vw/8)] text-center overflow-hidden",
        render: (value, row) => {
          const startDate = moment
            .utc(row.created_at)
            .local()
            .format("DD/MM/YYYY");
          return (
            <div className="overflow-hidden text-center w-full max-w-[calc(100vw/8)]">
              {`${startDate}`}
            </div>
          );
        },
      },
      {
        id: "work_plan_name",
        title: "Nama Proyek",
        dataIndex: "work_plan_name",
        className: "",
        fixed: "center",
        columnClassName: "w-[calc(100vw/10)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/10)] ">
            {value}
          </div>
        ),
      },
      {
        id: "detail_activities",
        title: "Jumlah Aktifitas",
        dataIndex: "detail_activities",
        className: "",
        fixed: "center",
        columnClassName:
          "w-[calc(100vw/8)] text-center whitespace-break-spaces",
        sortable: true,
        render: (value) => {
          return (
            <div className="overflow-hidden text-center w-full max-w-[calc(100vw/6)] ">
              {value?.length}
            </div>
          );
        },
      },
      {
        id: "total_withdrawal_fund",
        title: "Total Nilai Penarikan",
        dataIndex: "total_withdrawal_fund",
        className: "",
        columnClassName:
          "w-[calc(100vw/8)] text-center whitespace-break-spaces",
        sortable: true,
        fixed: "center",
        render: (value) => {
          return (
            <div className="overflow-hidden text-center text-ellipsis w-full max-w-[calc(100vw/8)]">
              {formatCurrency(value)}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        columnClassName:
          "w-[calc(100vw/10)] text-center whitespace-break-spaces",
        sortable: true,
        fixed: "center",
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },
      {
        id: "_id",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                {["NEED_APPROVAL", "NEED_APPROVAL_DIRECTOR"].includes(
                  data.status
                ) ? (
                  <div className="space-x-3 justify-center flex w-full">
                    <Button
                      type="button"
                      className={`
                        text-white w-40
                          bg-[#00BD52]
                        `}
                      onClick={() => {
                        localStorage.setItem(
                          "withdrawalWorkPlan",
                          value.toString()
                        );
                        navigate(
                          "/approval-rencana-penarikan-dana/verification"
                        );
                      }}
                    >
                      Review
                    </Button>
                  </div>
                ) : (
                  <div className="space-x-3 flex w-full">
                    <MdOutlineRemoveRedEye
                      className="text-gray-600 cursor-pointer flex-1"
                      size={28}
                      onClick={() => {
                        localStorage.setItem(
                          "withdrawalWorkPlan",
                          value.toString()
                        );
                        navigate("/approval-rencana-penarikan-dana/detail");
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, [navigate, page, rowPage]);

  const onFilterChange = ({ name, status }) => {
    setNameFilter(name);
    setStatusFilter(status);
  };

  useEffect(() => {
    refetch();
  }, [refetch, nameFilter, statusFilter, page, rowPage, sortMethod]);

  return (
    <div>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}

      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}

      <BasicList
        links={[
          {
            label: "Daftar Pengajuan Rencana Penarikan Dana",
          },
        ]}
        title={"Daftar Pengajuan Rencana Penarikan Dana"}
        columns={tableColumns}
        dataSource={withdrawalFundData}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        className={`!w-full`}
        pagination={paginationWithdrawalFundData}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={<ListFilter onFilterChange={onFilterChange} />}
      />
    </div>
  );
};

export default SubmissionWithdrawalFund;
