import { Navigate } from 'react-router';

import ForgotPassword from 'pages/ForgotPassword';
import ProgramList from 'pages/ProgramList';
import ProposalEvaluated from 'pages/ProposalEvaluated';
import ProposalEvaluatedDetail from 'pages/ProposalEvaluated/Detail';
import ProposalSubbmission from 'pages/ProposalSubmission';
import ProposalSubmissionDetail from 'pages/ProposalSubmission/Detail';
import User from 'pages/User';
import Verification from 'pages/Verification';
import Dashboard from 'pages/Dashboard';
import DashboardToc from 'pages/DashboardToc';
import DashboardDonor from 'pages/DashboardDonor';
import VerificationDetail from 'pages/Verification/Detail';
import PengalamanProgram from 'pages/Verification/Detail/PengalamanProgram';
import ProfileOrganization from 'pages/Verification/Detail/ProfileOrganization';
import ProfileUmum from 'pages/Verification/Detail/ProfileUmum';
import ProfilKeuangan from 'pages/Verification/Detail/ProfilKeuangan';
import Safeguards from 'pages/Verification/Detail/Safeguards';
import SumberDaya from 'pages/Verification/Detail/SumberDaya';
import InvestmentPlan from 'pages/InvestmentPlan';
import AnnualWorkPlan from 'pages/AnnualWorkPlan';
import AddAnnualWorkPlan from 'pages/AnnualWorkPlan/add';
import RasioValue from 'pages/RasioValue';

import ActivityReport from 'pages/ActivityReport';
import NewActivityReport from 'pages/ActivityReport/new';

import SafeguardImplementation from 'pages/SafeguardImplementation';
import SafeguardImplementationDetail from 'pages/SafeguardImplementation/detail';

import CommitmentSafeguard from 'pages/CommitmentSafeguard';
import CommitmentSafeguardDetail from 'pages/CommitmentSafeguard/Detail';

import ProgressReport from 'pages/ProgressReport';
import ProcurementPlan from 'pages/ProcurementPlan';
import AddInvestmentPlanWithContext from 'pages/InvestmentPlan/add';
import DetailInvestmentPlanWithContext from 'pages/InvestmentPlan/detail';

import RencanaKerjaWaktu from 'pages/RencanaKerjaWaktu';
import FormRencanaKerjaWaktu from 'pages/RencanaKerjaWaktu/Form';
import DetailAnnualWorkPlanWuthContext from 'pages/AnnualWorkPlan/detail';
import ListCallForProposal from 'pages/CallForProposal';
import CallForProposalDetail from 'pages/CallForProposal/Detail';

import ListConsepNotes from 'pages/ConsepNotes';
import DetailConsepNote from 'pages/ConsepNotes/detail';
import approvalConsepNote from 'pages/ConsepNotes/approval';
import DetailRencanaKerjaWaktu from 'pages/RencanaKerjaWaktu/Detail';
import SubmissionWithdrawalFund from 'pages/PengajuanRencanaPenarikanDana';
import FormRencanaPenarikanDana from 'pages/RencanaPenarikanDana/Form';
import RencanaPenarikanDana from 'pages/RencanaPenarikanDana';
import Tor from 'pages/Tor';
import TorCreate from 'pages/Tor/Create';
import InstitutionalMinistry from 'pages/InstitutionalMinistry';
import InstitutionalMinistryCreate from 'pages/InstitutionalMinistry/Create';
import ImplementingPartner from 'pages/ImplementingPartner';
import ImplementingPartnerCreate from 'pages/ImplementingPartner/Create';
import ImplementingPartnerAddProject from 'pages/ImplementingPartner/Create/add-project';
import ImplementingPartnerEdit from 'pages/ImplementingPartner/Edit';
import InstitutionalMinistryEdit from 'pages/InstitutionalMinistry/Edit';
import ImplementingPartnerProject from 'pages/ImplementingPartnerProject';
import ImplementingPartnerProjectCreate from 'pages/ImplementingPartnerProject/Create';

import PembayaranResources from 'pages/PembayaranResources';
import PembayaranResourcesForm from 'pages/PembayaranResources/add';
import PembayaranResourcesDetail from 'pages/PembayaranResources/detail';
import ApprovalPembayaranLemtara from 'pages/ApprovalPembayaranLemtara';
import ApprovalPembayaranLemtaraDetail from 'pages/ApprovalPembayaranLemtara/detail';
import ApprovalPembayaranLemtaraReview from 'pages/ApprovalPembayaranLemtara/review';
import ApprovalPembayaranResources from 'pages/ApprovalPembayaranResources';
import ApprovalPembayaranResourcesDetail from 'pages/ApprovalPembayaranResources/detail';
import ApprovalPembayaranResourcesReview from 'pages/ApprovalPembayaranResources/review';
import ImplementingPartnerPayment from 'pages/ImplementingPartnerPayment';
import ImplementingPartnerPaymentCreate from 'pages/ImplementingPartnerPayment/Create';
import ListPermohonanPenyaluranLangsung from 'pages/PermohonanPenyaluranLangsung';
import FormPermohonanPenyaluranLangsung from 'pages/PermohonanPenyaluranLangsung/Form';
import ImplementingPartnerPaymentDetail from 'pages/ImplementingPartnerPayment/Detail';
import ApprovalImplementingPartnerPayment from 'pages/ApprovalImplementingPartnerPayment';
import ApprovalImplementingPartnerPaymentDetail from 'pages/ApprovalImplementingPartnerPayment/Detail';
import ListPenyaluranLangsung from 'pages/PenyaluranLangsung';
import DetailPenyaluranLangsung from 'pages/PenyaluranLangsung/Detail';
import ImplementingPartnerDetail from 'pages/ImplementingPartner/Detail';
import ImplementingPartnerProjectEdit from 'pages/ImplementingPartnerProject/Edit';
import ImplementingPartnerProjectDetail from 'pages/ImplementingPartnerProject/Detail';
import TimPengelolaProyek from 'pages/TimPengelolaProyek';
import FormTimPengelolaProyek from 'pages/TimPengelolaProyek/Form';
import ImplementingPartnerPaymentEdit from 'pages/ImplementingPartnerPayment/Edit';
import LaporanKegiatan from 'pages/LaporanKegiatan';
import LaporanKemajuan from 'pages/LaporanKemajuan';
import FormLaporanKegiatan from 'pages/LaporanKegiatan/Form';
import FormLaporanKemajuan from 'pages/LaporanKemajuan/Form';
import DetailLaporanKemajuan from 'pages/LaporanKemajuan/Detail';
import ProgressReportForm from 'pages/ProgressReport/Form';
import ProgressReportDetail from 'pages/ProgressReport/Detail';
import FormPenetupanProyek from 'pages/InvestmentPlan/ProjectClosure';
import FormPenutupanSafeguard from 'pages/InvestmentPlan/SafeguardClosure';

const routesName = [
  {
    routeType: 'private',
    path: '/',
    element: () => <Navigate to="/dashboard" />,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/dashboard',
    element: Dashboard,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/dashboard-toc',
    element: DashboardToc,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/donor',
    element: DashboardDonor,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/forgot-password',
    element: ForgotPassword,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/user',
    element: User,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/pengajuan-dana-program',
    element: ProposalSubbmission,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/pengajuan-dana-program/detail',
    element: ProposalSubmissionDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/menilai-proposal',
    element: ProposalEvaluated,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/menilai-proposal/detail',
    element: ProposalEvaluatedDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/daftar-program',
    element: ProgramList,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification',
    element: Verification,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/rasio-value',
    element: RasioValue,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail',
    element: VerificationDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail/profile-umum',
    element: ProfileUmum,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail/profile-organisasi',
    element: ProfileOrganization,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail/sumber-daya',
    element: SumberDaya,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail/profil-keuangan',
    element: ProfilKeuangan,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/verification/detail/pengalaman-program',
    element: PengalamanProgram,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan',
    element: InvestmentPlan,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/add',
    element: AddInvestmentPlanWithContext,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/:id',
    element: DetailInvestmentPlanWithContext,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/project-closure/:id',
    element: FormPenetupanProyek,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/project-closure/detail/:id',
    element: FormPenetupanProyek,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/safeguard-closure/:id',
    element: FormPenutupanSafeguard,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/investment-plan/safeguard-closure/detail/:id',
    element: FormPenutupanSafeguard,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/verification/detail/safeguards',
    element: Safeguards,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/annual-work-plan',
    element: AnnualWorkPlan,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/annual-work-plan/add',
    element: AddAnnualWorkPlan,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/annual-work-plan/:id',
    element: DetailAnnualWorkPlanWuthContext,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/activity-report',
    element: ActivityReport,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/activity-report/form',
    element: NewActivityReport,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/activity-report/form/:id',
    element: NewActivityReport,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/progress-report',
    element: ProgressReport,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/progress-report/add',
    element: ProgressReportForm,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/progress-report/detail/:id',
    element: ProgressReportDetail,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/progress-report/edit/:id',
    element: ProgressReportForm,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/safeguard-implementation',
    element: SafeguardImplementation,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/safeguard-implementation/detail/:id',
    element: SafeguardImplementationDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/commitment-safeguard',
    element: CommitmentSafeguard,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/commitment-safeguard/set/:id',
    element: CommitmentSafeguardDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/procurements',
    element: ProcurementPlan,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/tor',
    element: Tor,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/tor/create',
    element: TorCreate,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/tor/detail',
    element: TorCreate,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/tor/revision',
    element: TorCreate,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-kerja-waktu',
    element: RencanaKerjaWaktu,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-kerja-waktu/add',
    element: FormRencanaKerjaWaktu,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-kerja-waktu/revision',
    element: FormRencanaKerjaWaktu,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-kerja-waktu/:id',
    element: DetailRencanaKerjaWaktu,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-penarikan-dana',
    element: RencanaPenarikanDana,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-penarikan-dana/add',
    element: FormRencanaPenarikanDana,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/rencana-penarikan-dana/detail',
    element: FormRencanaPenarikanDana,
  },
  {
    routeType: 'public',
    path: '/call-for-proposal',
    element: ListCallForProposal,
    exact: true,
  },
  {
    path: '/rencana-kerja-waktu/detail',
    element: DetailRencanaKerjaWaktu,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/call-for-proposal/:id',
    element: CallForProposalDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/concept-notes',
    element: ListConsepNotes,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/concept-notes/:id',
    element: DetailConsepNote,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/concept-notes/approval/:id',
    element: approvalConsepNote,
  },

  {
    routeType: 'private',
    path: '/approval-rencana-penarikan-dana/',
    element: SubmissionWithdrawalFund,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-rencana-penarikan-dana/verification',
    element: FormRencanaPenarikanDana,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-rencana-penarikan-dana/detail',
    element: FormRencanaPenarikanDana,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/institutional-ministry',
    element: InstitutionalMinistry,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/institutional-ministry/create',
    element: InstitutionalMinistryCreate,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/institutional-ministry/:id',
    element: InstitutionalMinistryEdit,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner',
    element: ImplementingPartner,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner/create',
    element: ImplementingPartnerCreate,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner/edit/:id',
    element: ImplementingPartnerEdit,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner/:id',
    element: ImplementingPartnerDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner/add-project/:id',
    element: ImplementingPartnerAddProject,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-project',
    element: ImplementingPartnerProject,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-project/create',
    element: ImplementingPartnerProjectCreate,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-project/edit/:id',
    element: ImplementingPartnerProjectEdit,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-project/:id',
    element: ImplementingPartnerProjectDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-payment',
    element: ImplementingPartnerPayment,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-payment/create',
    element: ImplementingPartnerPaymentCreate,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-payment/:id',
    element: ImplementingPartnerPaymentDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/implementing-partner-payment/edit/:id',
    element: ImplementingPartnerPaymentEdit,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/pembayaran-resources',
    element: PembayaranResources,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/pembayaran-resources/add',
    element: PembayaranResourcesForm,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/pembayaran-resources/:id',
    element: PembayaranResourcesDetail,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/approval-pembayaran-implementing-partner',
    element: ApprovalImplementingPartnerPayment,
    exact: true,
  },
  {
    routeType: 'public',
    path: '/approval-pembayaran-implementing-partner/:id',
    element: ApprovalImplementingPartnerPaymentDetail,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-lemtara',
    element: ApprovalPembayaranLemtara,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-lemtara/:id/detail',
    element: ApprovalPembayaranLemtaraDetail,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-lemtara/review/:id',
    element: ApprovalPembayaranLemtaraReview,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-resources',
    element: ApprovalPembayaranResources,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-resources/:id',
    element: ApprovalPembayaranResourcesDetail,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-pembayaran-resources/review/:id',
    element: ApprovalPembayaranResourcesReview,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/permohonan-penyaluran-langsung',
    element: ListPermohonanPenyaluranLangsung,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/permohonan-penyaluran-langsung/form',
    element: FormPermohonanPenyaluranLangsung,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/permohonan-penyaluran-langsung/form:id',
    element: FormPermohonanPenyaluranLangsung,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-penyaluran-langsung',
    element: ListPenyaluranLangsung,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/approval-penyaluran-langsung/:id',
    element: DetailPenyaluranLangsung,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/project-management-team',
    element: TimPengelolaProyek,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/project-management-team/assign',
    element: FormTimPengelolaProyek,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/activity-report-review',
    element: LaporanKegiatan,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/activity-report-review/detail/review',
    element: FormLaporanKegiatan,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/progress-report-review',
    element: LaporanKemajuan,
    exact: true,
  },
  {
    routeType: 'private',
    path: '/progress-report-review/review',
    element: DetailLaporanKemajuan,
    exact: true,
  },
];

export default routesName;
