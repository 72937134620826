import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Table,
} from "components";
import { useNavigate, useParams } from "react-router";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { getDetailProposal } from "services/danaProgram/callForProposalService";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { formatCurrency } from "helpers";

const WorkPlanDetail = ({onNext, onPrev}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  let { id } = useParams();

  const methods = useForm({
    mode: "onChange",
  });

  const { control } = { ...methods };

  const {
    fields: fieldsWorkPlan,
    append: appendWorkPlan,
    remove: removeWorkPlan,
  } = useFieldArray({
    control,
    name: "workPlan",
  });

  const fetchDetailCfp = async (params) => {
    const res = await getDetailProposal(id, params);
    setData(res.data.data);
    appendWorkPlan({
      is_conservation_area: res.data.data?.is_conservation_area,
      is_allowed: res.data.data?.is_allowed
    });
  }

  useEffect(() => {
    fetchDetailCfp({category : 'work-plan'})
  }, [])

  const tableColumns = useMemo(() => {
    return [
      {
        id: "item_name",
        title: "Nama Item",
        dataIndex: "item_name",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "quantity",
        title: "Qty",
        dataIndex: "quantity",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "unit",
        title: "satuan/unit",
        dataIndex: "unit",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "item_price",
        title: "harga satuan",
        dataIndex: "item_price",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {formatCurrency(value)}
          </div>
        ),
      },
      {
        id: "volume",
        title: "Volume",
        dataIndex: "volume",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "sub_total",
        title: "Sub Total",
        dataIndex: "sub_total",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {formatCurrency(data?.quantity * data?.item_price)}
            </div>
          )
        }
      }
    ];
  }, []);

  const totalAmount = useMemo(() => {
    const sum = calculateTotalAmount(data?.list_of_items || []);

    return formatCurrency(sum);
  }, [data]);

  function calculateTotalAmount(items) {
    return items.reduce((total, item) => {
      const amountRow = item.quantity * item.item_price;
      return total + amountRow;
    }, 0);
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div>Rencana Kerja</div>
        </CardHeader>
        <CardBody>
            <FormProvider {...methods}>
              <div className="flex flex-col space-y-5">
                <CardForm label="Kegiatan" buttonCollapse={true}>
                  <div className="flex flex-col space-y-5">
                    <div className="flex flex-col">
                      <label className="text-[#1D2939] font-bold">
                          Nama Kegiatan
                      </label>
                      <label className="text-[#1D2939]">
                          {data?.activity_name}
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-[#1D2939] font-bold">
                          Deskripsi
                      </label>
                      <label className="text-[#1D2939]">
                          {data?.activity_description}
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex w-full">
                          <span className="flex-1 font-bold">Kategori Kegiatan</span>
                          <span className="flex-1 font-bold">Rencana Waktu Pelaksanaan</span>
                          <span className="flex-1 font-bold">Penanggung Jawab</span>
                      </div>
                      <div className="flex w-full">
                          <span className="flex-1">{data?.activity_category}</span>
                          <span className="flex-1">{data?.time_plan_start.split('T')[0]}</span>
                          <span className="flex-1">{data?.pic}</span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-[#1D2939] font-bold">
                          Means of verification
                      </label>
                      <div className="pl-6">
                        <ul className="list-disc">
                          {
                           data?.means_of_verification.length > 0 ? data?.means_of_verification.map((item, index) => {
                            return (<li key={`means_of_verification-${index}`}>{item}</li>)
                           }): (<></>)
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-5 border-y py-4">
                    {
                      data?.indicator.length > 0 ? data?.indicator.map((item,index) => {
                        return (
                        <CardForm label={`Indikator ${index+1} - ${item.indicator}`} buttonCollapse={true}>
                          <div className="flex flex-col space-y-5">
                            <div className="flex flex-col">
                              <label className="text-[#1D2939] font-bold">
                                Indicator
                              </label>
                              <label className="text-[#1D2939]">
                                {item.indicator}
                              </label>
                            </div>
                            {item?.parameter?.map(param => (
                              <div className="flex flex-col">
                                <div className="flex w-full">
                                    <span className="flex-1 font-bold">Baseline</span>
                                    <span className="flex-1 font-bold">Target Kontribusi</span>
                                    <span className="flex-1 font-bold">Satuan / unit</span>
                                </div>
                                <div className="flex w-full">
                                    <span className="flex-1">{param?.baseline ?? '-'}</span>
                                    <span className="flex-1">{param?.target ?? '-'}</span>
                                    <span className="flex-1">{param?.satuan ?? '-'}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </CardForm>
                        )
                      }): (<></>)
                    }
                  </div>
                  <div className="flex flex-col space-y-5">
                    <label className="text-[#1D2939] font-bold">
                        Daftar Item Kebutuhan
                    </label>
                    <Table
                      bordered
                      stripped
                      layout="auto"
                      columns={tableColumns}
                      dataSource={data?.list_of_items}
                      isLoading={false}
                      onChangePage={null}
                      onChangeRowsPerPage={null}
                      isPagination={false}
                    />
                    <span>
                      Total Kebutuhan: &nbsp;
                      <strong>{totalAmount}</strong>
                    </span>
                  </div>
                </CardForm>
              </div>
            </FormProvider>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={onPrev}
          >
            <RiArrowLeftLine/>
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
        <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={() => onNext()}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkPlanDetail;
