/* eslint-disable no-useless-computed-key */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { BiPencil, BiTrash } from "react-icons/bi";
import { createPortal } from "react-dom";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { SuccessToast, ToastContext } from "components/atoms/Toast";
import { ConfirmationModal } from "components/molecules/Modal";
import { BadgeStatus as ApprovalBadgeStatus } from "components/organisms/Approval2Level";
import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";

import { formattedValue } from "helpers";
import { deletePembayaranResource } from "services/danaProgram/pembayaranResourceService";

import { usePembayaranResources } from "./hooks";

const PembayaranResources = () => {
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState({ isOpen: false, type: "", data: null });
  const [sortBy, setSortBy] = useState("");
  const [sortMethod, setSortMethod] = useState("desc");
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const navigate = useNavigate();
  const links = [
    {
      label: "Daftar Pembayaran Resources Proyek",
    },
  ];

  const params = {
    page: page,
    limit: limit,
    search: keyword,
    status: status,
    order_method: sortMethod,
    order_by: sortBy,
  };

  const {
    dataPembayaranResources,
    isLoadingPembayaranResources,
    refetchPembayaranResources,
    paginationPembayaranResources,
  } = usePembayaranResources(params);

  useEffect(() => {
    refetchPembayaranResources();
  }, [keyword, status, page, limit, sortBy, sortMethod]);

  const onSearch = debounce(
    useCallback((event) => {
      setKeyword(() => {
        if (event?.target?.value) return event.target.value;
        return undefined;
      });
    }, []),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatus(obj.value || undefined);
  }, []);

  const deleteDataHandler = useMutation({
    mutationKey: ["delete-pembayaran-resource"],
    mutationFn: async (id) => {
      const res = await deletePembayaranResource({ id: id });

      return res?.data?.data;
    },
    onSuccess: async () => {
      showToastMessage(
        "Data berhasil dihapus!",
        "Data yang anda pilih berhasil dihapus."
      );
      setShowToast((prev) => ({ ...prev, success: true }));
      setModal((prev) => ({ ...prev, data: null, isOpen: false, type: "" }));
      refetchPembayaranResources();
    },
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "",
        sortable: true,
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;

          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "created_at",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[180px]",
        render: (value) => {
          const Date = moment.utc(value).local().format("DD/MM/YYYY");

          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {Date}
            </div>
          );
        },
      },
      {
        id: "transaction_date",
        title: "Tanggal Pembayaran",
        dataIndex: "transaction_date",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[180px]",
        render: (value) => {
          const Date = moment.utc(value).local().format("DD/MM/YYYY");

          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {Date}
            </div>
          );
        },
      },
      {
        id: "project_name",
        title: "Nama Proyek",
        dataIndex: "project_name",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[150px]">
              {value}
            </div>
          );
        },
      },
      {
        id: "",
        title: "Pengajuan Pembayaran",
        dataIndex: "",
        className: "",
        sortable: true,
        columnClassName: "w-[180px]",
        render: (value) => {
          return (
            <div className="text-center w-full max-w-[180px]">
              Pembayaran Resource Proyek
            </div>
          );
        },
      },
      {
        id: "payment_total",
        title: "Nilai Total Transaksi",
        dataIndex: "payment_total",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[180px]",
        render: (value) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[180px]">
              {formattedValue(value)}
            </div>
          );
        },
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        className: "",
        sortable: true,
        sorter: (method, column) => {
          setSortBy(column);
          setSortMethod(method);
        },
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        render: (value) => <ApprovalBadgeStatus value={value} />,
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        columnClassName: "w-[150px] text-ellipsis overflow-hidden",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="space-x-3 flex items-center w-full justify-center">
              <AiOutlineEye
                className="text-gray-600 cursor-pointer"
                size={20}
                onClick={() => navigate(`/pembayaran-resources/${data?._id}`)}
              />
              {data?.status !== "APPROVED" && (
                <BiPencil
                  className="text-gray-600 cursor-pointer"
                  size={20}
                  onClick={() =>
                    navigate(`/pembayaran-resources/add`, {
                      state: {
                        id: data?._id,
                      },
                    })
                  }
                />
              )}
              {data?.status !== "APPROVED" && (
                <BiTrash
                  className="text-gray-600 cursor-pointer"
                  size={20}
                  onClick={() => {
                    setModal((prev) => ({
                      ...prev,
                      type: "delete",
                      data: data,
                      isOpen: true,
                    }));
                  }}
                />
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <Fragment>
      {createPortal(
        <ConfirmationModal
          open={modal?.type === "delete" && modal?.isOpen}
          onClose={() =>
            setModal((prev) => ({ ...prev, type: "", isOpen: false }))
          }
          onSubmit={() => deleteDataHandler.mutate(modal?.data?._id)}
        >
          <div>
            <div className="text-gray-900 text-lg font-semibold">
              Apakah Anda yakin akan menghapus data ini?
            </div>
          </div>
        </ConfirmationModal>,
        document.body,
        "show-delete-pembayaran-resource-modal"
      )}
      {showToast?.success &&
        createPortal(
          <SuccessToast
            onClose={() => setShowToast(initialShowToast)}
            message={toastMessage}
            message_description={toastDescription}
          />,
          document.body,
          "success-toast-pembayaran-resource-list"
        )}
      <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[75vw]">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3">
          <TitleText className="flex-1 text-gray-900 text-lg font-semibold">
            Daftar Pembayaran Resources Proyek
          </TitleText>
          <div className="flex space-x-4">
            {/* <Button
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            >
              Download Template
            </Button> */}
            <Button
              onClick={() => navigate("/pembayaran-resources/add")}
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              startIcon={<AiOutlinePlus className="text-white" />}
            >
              Tambah
            </Button>
          </div>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <div className="flex space-x-4">
                <div className="flex-1">
                  <SearchInput placeholder="Cari" onChange={onSearch} />
                </div>
                <CustomSelect
                  optionsData={APPROVAL_STATUSSES}
                  placeholder="Status"
                  className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  onChange={onChangeStatus}
                />
              </div>
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={dataPembayaranResources}
                isLoading={isLoadingPembayaranResources}
                onChangePage={setPage}
                onChangeRowsPerPage={setLimit}
                pagination={paginationPembayaranResources}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
                setSortBy={setSortBy}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PembayaranResources;
