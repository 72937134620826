export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const isNotEmpty = (value) => {
  return value.trim() !== "";
};

export const removeWords = (str, wordsToRemove) => {
  return str.replace(wordsToRemove, "");
};

export function findKeyName(obj, searchKey) {
  for (const key in obj) {
    if (key === searchKey) {
      return true;
    }
  }
  return false;
}

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
  return message;
};

export function humanizeFileSize(bytes, fractionDigit = 1, unit = "KB") {
  const divider = 1024;

  if (bytes < divider) return `${bytes} B`;

  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let unitIdx = -1;
  let size = bytes;

  do {
    size = size / divider;
    ++unitIdx;
  } while (size > divider && unitIdx < units.length - 1);

  return `${size.toFixed(fractionDigit)} ${unit}`;
}

export const provinces = [
  { value: "1", label: "Aceh" },
  { value: "2", label: "Bali" },
  { value: "3", label: "Bangka Belitung" },
  { value: "4", label: "Banten" },
  { value: "5", label: "Bengkulu" },
  { value: "6", label: "Central Java" },
  { value: "7", label: "Central Kalimantan" },
  { value: "8", label: "Central Sulawesi" },
  { value: "9", label: "East Java" },
  { value: "10", label: "East Kalimantan" },
  { value: "11", label: "East Nusa Tenggara" },
  { value: "12", label: "Gorontalo" },
  { value: "13", label: "Jakarta Special Capital Region" },
  { value: "14", label: "Jambi" },
  { value: "15", label: "Lampung" },
  { value: "16", label: "Maluku" },
  { value: "17", label: "North Kalimantan" },
  { value: "18", label: "North Maluku" },
  { value: "19", label: "North Sulawesi" },
  { value: "20", label: "North Sumatra" },
  { value: "21", label: "Papua" },
  { value: "22", label: "Papua Barat" },
  { value: "23", label: "Riau" },
  { value: "24", label: "Riau Islands" },
  { value: "25", label: "South Kalimantan" },
  { value: "26", label: "South Sulawesi" },
  { value: "27", label: "South Sumatra" },
  { value: "28", label: "Southeast Sulawesi" },
  { value: "29", label: "West Java" },
  { value: "30", label: "West Kalimantan" },
  { value: "31", label: "West Nusa Tenggara" },
  { value: "32", label: "West Papua" },
  { value: "33", label: "West Sulawesi" },
  { value: "34", label: "West Sumatra" },
  { value: "35", label: "Yogyakarta Special Region" },
];

export const cities = [
  { value: "1", label: "Bandung" },
  { value: "2", label: "Denpasar" },
];

export const districts = [
  { value: "1", label: "Bandung Utara" },
  { value: "2", label: "Bandung Timur" },
  { value: "3", label: "Bandung Selatan" },
  { value: "4", label: "Bandung Barat" },
];

export const villages = [
  { value: "1", label: "Cijawura" },
  { value: "2", label: "Cigondewah Kidul" },
  { value: "3", label: "Cigondewah Rahayu" },
  { value: "4", label: "Cisaranten Kidul" },
  { value: "5", label: "Cisaranten Bina Harapan" },
];

export const zipCodes = [
  { value: "1", label: "40393" },
  { value: "2", label: "40394" },
  { value: "3", label: "40395" },
  { value: "4", label: "40396" },
  { value: "5", label: "40397" },
];

export const branches = [
  { value: "1", label: "2" },
  { value: "2", label: "5" },
  { value: "10", label: "10" },
];

export const formattedValue = (value, withPrefix = true) => {
  const stringValue = String(value);
  // replace all non-digit characters with empty string
  const number = stringValue.replace(/\D/g, "");

  const formatted = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(number);

  return withPrefix ? formatted : formatted.replace("Rp", "").trim();
};

export function formatCurrency(amount) {
  if (isNaN(amount)) {
    return "Invalid input";
  }
  const [integerPart, decimalPart] = amount.toFixed(0).split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const formattedCurrency = `Rp. ${formattedInteger}${
    decimalPart ? `,${decimalPart}` : ""
  }`;

  return formattedCurrency;
}

export const convertMoney = (value) => {
  let oriValue = String(value);
  oriValue = oriValue.includes(".") ? oriValue.split(".").join("") : oriValue;
  let modifValue = "";
  let count = 1;
  if (oriValue !== "" && oriValue.length > 3) {
    const splitValue = oriValue.split("");
    for (let i = splitValue.length - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0 && i !== 0) {
        modifValue = `.${splitValue[i]}${modifValue}`;
      } else {
        modifValue = `${splitValue[i]}${modifValue}`;
      }
      count += 1;
    }
  } else {
    modifValue = oriValue;
  }

  return modifValue;
};

export const deconvertMoney = (formattedValue, method) => {
  if (formattedValue === null) return 0;
  if (formattedValue === undefined) return 0;

  const oriValue = formattedValue.split(".").join("");
  return oriValue;
};
export const changeAreaCoverage = ({
  isArray = false,
  index = 0,
  value = "",
  fieldChange = "",
  options = {},
  masterData = {},
}) => {
  let tempArr;
  let decidedMasterData = masterData[fieldChange].filter(
    (e) => e.extraId === value
  );
  if (decidedMasterData.length === 0)
    decidedMasterData = masterData[fieldChange];
  if (isArray) {
    tempArr = [...options];
    tempArr[index][fieldChange] = decidedMasterData;
  } else {
    tempArr = { ...options };
    tempArr[fieldChange] = decidedMasterData;
  }
  return tempArr;
};

export const calculateTax = (value, typeTax) => {
  return value * (typeTax / 100);
};

export function generateUUID() {
  // Helper function to generate a random hex digit
  function randomHexDigit() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  // Concatenating random hex digits to form a UUID v4
  return (
    randomHexDigit() +
    randomHexDigit() +
    "-" +
    randomHexDigit() +
    "-" +
    "4" +
    randomHexDigit().substr(0, 3) +
    "-" +
    (Math.floor(Math.random() * 4) + 8).toString(16) +
    randomHexDigit().substr(0, 3) +
    "-" +
    randomHexDigit() +
    randomHexDigit() +
    randomHexDigit()
  );
}
