import React, { useState, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  Input,
} from "components";
import { useForm } from "react-hook-form";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { RiArrowLeftLine, RiArrowRightLine, RiSave3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import * as yup from "yup";
import _ from "lodash";
import config from "app/config";
import { UseAnnualWorkPlanContext } from "../../../context/AnnualWorkPlanContext";
import { useQuery } from "@tanstack/react-query";
import { getListMainActivity } from "services/masterData/mainActivity";
import Select from "react-select";
import axiosInstance from "app/interceptors";
import { ConfirmationModal } from "components/molecules/Modal";

const validationSchema = yup.object().shape({
  name: yup.string().required("Wajib diisi"),
});

const AddAWPMainActivity = () => {
  const parentOutputActivity = {
    "": [],
  };

  const [outputActivity, setOutputActivity] = useState(parentOutputActivity);
  const [detailAWP, setDetailAWP] = useState(null);
  const {
    idAwp,
    setData,
    updateData,
    setCurrentStep,
    currentStep,
    dataAnnualWorkPlan,
    type,
  } = UseAnnualWorkPlanContext();
  const [formIsError, setFormIsError] = useState(true);
  const [activityOption, setActivityOption] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const links = [
    {
      label: "Pengajuan Dana",
      path: "/",
    },
    {
      label: "Annual Work Plan",
      path: "/annual-work-plan",
    },
    {
      label: "Tambah Permohonan",
    },
  ];

  const step = Number(location.pathname.split("/")[3]);

  const stepForm = [
    "General Informasi",
    "Kegiatan Utama",
    "Detail/Sub Kegiatan",
    "Anggaran Biaya",
    "Dokumen Pendukung",
  ];

  useEffect(() => {
    if (idAwp) fetchDetailAWP();
  }, [idAwp]);

  useEffect(() => {
    if (!_.isEmpty(detailAWP?.output)) {
      let empty = {};
      _.map(detailAWP.output, (output, key) => {
        empty[output.name + ` (${output._id})`] = output.activity.map(
          (activity) => ({
            ...activity,
            id: activity._id,
            main_activity_id: activity.main_activity_id,
            activity: activity.name,
            isProcurement: activity.procurement ? "yes" : "no",
          })
        );
      });

      setOutputActivity(empty);
    }
  }, [detailAWP]);

  useEffect(() => {
    if (!_.isEmpty(dataAnnualWorkPlan?.output)) {
      let empty = {};
      _.map(dataAnnualWorkPlan.output, (output, key) => {
        empty[output.name + ` (${output._id})`] = output.activity.map(
          (activity) => ({
            id: activity._id,
            activity: activity.name,
            main_activity_id: activity.main_activity_id,
            isProcurement: activity.procurement ? "yes" : "no",
          })
        );
      });

      setOutputActivity(empty);
    }
  }, [dataAnnualWorkPlan]);

  const amountTotalActivity = useMemo(() => {
    if (Object.keys(outputActivity).length > 0) {
      return Object.keys(outputActivity)
        .map((label) => outputActivity[label].length)
        .reduce((a, b) => a + b);
    }
  }, [outputActivity]);

  const deleteActivityHandler = (label, childKey) => {
    let selectedChild = outputActivity[label].filter((_, i) => i !== childKey);

    setOutputActivity((prev) => ({
      ...prev,
      [label]: selectedChild,
    }));
  };

  const addActivityHandler = (label) => {
    const newField = {
      activity: { value: "", label: "" },
      isProcurement: "",
      isError: true,
      message: "",
    };

    setOutputActivity((prev) => ({
      ...prev,
      [label]: [...prev[label], newField],
    }));
  };

  const changeStatusProcurement = (label, childKey, value) => {
    let temp = outputActivity[label];
    temp[childKey].isProcurement = value;

    setOutputActivity((prev) => ({
      ...prev,
      [label]: temp,
    }));
  };

  const onChangeActivityHandler = (label, childKey, e) => {
    let temp = outputActivity[label];
    temp[childKey].main_activity_id = e.value;
    temp[childKey].activity = e.label;

    setOutputActivity((prev) => ({
      ...prev,
      [label]: temp,
    }));
  };

  // detecting validation at activity
  useEffect(() => {
    let arr = [];
    _.map(Object.keys(outputActivity), (label) => {
      _.map(outputActivity[label], (detail) => {
        arr.push(detail);
      });
    });

    if (arr.length > 0) {
      // if not detecting error validation or empty value
      if (
        !_.filter(
          arr,
          (x) => x.isProcurement.length == 0 || /^\s*$/.test(x.activity.value)
        ).length
      ) {
        setFormIsError(false);
      } else {
        setFormIsError(true);
      }
    } else {
      setFormIsError(true);
    }
  }, [outputActivity]);

  const fetchDetailAWP = async () => {
    const response = await axiosInstance.get(
      `${config.BASE_URL}/api/dana-program/v1/cms/awp?id=${idAwp}`
    );
    const data = await response.data.data;

    updateData({ dataAnnualWorkPlan: data });
    setDetailAWP(data);
  };

  const { data: dataMainActivity } = useQuery({
    queryKey: ["application-get-main-activity"],
    queryFn: async () => {
      const res = await getListMainActivity();

      return res?.data?.data || [];
    },
  });

  useEffect(() => {
    if (dataMainActivity?.length > 0) {
      let temp = dataMainActivity.map((el) => ({
        value: el?.id,
        label: el?.kegiatanUtama,
      }));
      setActivityOption(temp);
    }
  }, [dataMainActivity]);

  const onSubmit = async (data, draft = false) => {
    if (type === "show") {
      setCurrentStep({ currentStep: "3" });
    } else {
      try {
        const getPayloadOutput = _.map(
          Object.keys(outputActivity),
          (output) => {
            return {
            output_id: detailAWP.output.find(
              (x) => x.name === output.replace(/\s\([^)]+\)$/, "")
            )?._id,
            activities: outputActivity[output].map((activity) => ({
              main_activity_id: activity?.main_activity_id,
              ...activity?.id && { activity_id: activity.id },
              // main_activity_id: activity.main_activity_id,
              name: activity.activity,
              procurement: activity.isProcurement === "yes",
            })),
          }}
        );

        const payload = {
          id: idAwp,
          core_activities: getPayloadOutput,
        };

        const response = await axiosInstance.post(
          `${config.BASE_URL}/api/dana-program/v1/cms/awp/store/second-step`,
          payload
        );

        if (response.data.code === 200) {
          if(draft){
            setConfirmModal(false);
            // navigate("/annual-work-plan");
          }else{
            setCurrentStep({ currentStep: "3" });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={confirmModal}
        onClose={() => setConfirmModal(!confirmModal)}
        onSubmit={() => {
          onSubmit(true, true);
        }}
      >
        Apakah anda akan menyimpan sebagai draft?
      </ConfirmationModal>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      {/* <Breadcrumbs items={links} /> */}
      {/* Header */}
      {/* <div className="flex flex-col gap-1">
        <h1 className="font-medium text-3xl">Tambah Permohonan</h1>
        <p className="text-base">Silahkan daftar menggunakan data yang Anda miliki.</p>
      </div>

      <hr />

      <ProgressStepForm items={stepForm} step={step} /> */}

      {/* Information Bar */}
      {/* <div className="flex bg-[#FFFCF5] self-stretch p-4 gap-3 border border-[#F5AE70] rounded-xl">
        <FiAlertTriangle color="#BE600E" size={20} />
        <div className="flex flex-col gap-1">
          <p className="font-medium text-[#8F480A] text-sm">Lengkapi Data</p>
          <p className="text-sm">Silakan isi semua data dibawah ini untuk bisa melanjutkan pengisian ke tahap selanjutnya</p>
        </div>
      </div> */}

      {/* Card Content */}
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <div className="flex gap-2 items-center">
              <p>Rencana Kerja - Kegiatan Utama</p>
              <span className="bg-[#FFFAEB] p-1 font-medium text-xs text-[#B54708]">
                Total Kegiatan Utama: {amountTotalActivity} Kegiatan
              </span>
            </div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col gap-5">
              {_.map(Object.keys(outputActivity), (label, parentKey) => (
                <CardForm
                  key={"output-" + parentKey}
                  buttonCollapse
                  label={`Output ${parentKey + 1} - ${label.replace(
                    /\s\([^)]+\)$/,
                    ""
                  )}`}
                >
                  <div className="flex flex-col w-full gap-5">
                    {_.map(outputActivity[label], (field, childKey) => (
                      <div
                        key={"activity-" + childKey}
                        className="flex w-full gap-4"
                      >
                        <div className="w-[50%] space-y-[9px]">
                          <label className="text-[#1D2939] font-semibold text-sm">
                            Kegiatan Utama
                            <span className="ml-1 text-error-500 text-sm">
                              *
                            </span>
                          </label>
                          <Select
                            className="w-full text-sm remove-input-txt-border-react-select"
                            options={activityOption}
                            onChange={(e) => {
                              onChangeActivityHandler(label, childKey, e);
                            }}
                            value={
                              typeof field.activity === "string"
                                ? {
                                    value: field.activity,
                                    label: field.activity,
                                  }
                                : field.activity
                            }
                            isDisabled={type === "show"}
                          />
                        </div>

                        <div className="flex flex-col w-[42%] gap-2 mt-6">
                          <label className="text-[#1D2939] font-semibold text-sm">
                            Perlu Pengadaan?
                          </label>

                          <div className="flex gap-6">
                            <div className="flex gap-1 text-sm">
                              <Input
                                type="radio"
                                id={`${label}-${field.activity}-${field.id}-procurement-is-yes`}
                                defaultChecked={
                                  !!(field.isProcurement === "yes")
                                }
                                value="yes"
                                name={`${label}-${field.activity}-${field.id}-procurement`}
                                className="rounded-full"
                                onChange={() =>
                                  changeStatusProcurement(
                                    label,
                                    childKey,
                                    "yes"
                                  )
                                }
                                disabled={type === "show"}
                              />
                              <label
                                htmlFor={`${label}-${field.activity}-${field.id}-procurement-is-yes`}
                              >
                                Ya
                              </label>
                            </div>
                            <div className="flex gap-1 text-sm">
                              <Input
                                type="radio"
                                id={`${label}-${field.activity}-${field.id}-procurement-is-no`}
                                defaultChecked={
                                  !!(field.isProcurement === "no")
                                }
                                value="no"
                                name={`${label}-${field.activity}-${field.id}-procurement`}
                                className="rounded-full"
                                onChange={() =>
                                  changeStatusProcurement(label, childKey, "no")
                                }
                                disabled={type === "show"}
                              />
                              <label
                                htmlFor={`${label}-${field.activity}-${field.id}-procurement-is-no`}
                              >
                                Tidak
                              </label>
                            </div>
                          </div>
                        </div>
                        {childKey !== 0 && (
                          <div className="flex w-[5%] justify-end mt-6">
                            <Button
                              onClick={() =>
                                deleteActivityHandler(label, childKey)
                              }
                              className="bg-[#D92D20] text-white"
                              startIcon={<FaTrashAlt />}
                              disabled={type === "show"}
                            />
                          </div>
                        )}
                      </div>
                    ))}

                    <hr />

                    <div className="flex justify-end">
                      <Button
                        type="button"
                        className="bg-white border-[#D0D5DD]"
                        startIcon={<FaPlus />}
                        onClick={() => addActivityHandler(label)}
                        disabled={type === "show"}
                      >
                        Kegiatan Utama
                      </Button>
                    </div>
                  </div>
                </CardForm>
              ))}

              <hr />
            </div>
          </CardBody>
        </Card>
      </div>

      {/* Button */}
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: "1" })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          {type !== "show" && (
            <Button type="button" onClick={() => setConfirmModal(true)}>
              <RiSave3Line />
              Simpan & Draft
            </Button>
          )}
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={onSubmit}
            disabled={type !== "show" && formIsError}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
        {renderModal()}
      </div>
    </div>
  );
};

export default AddAWPMainActivity;
