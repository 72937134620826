import {
  Card,
  CardBody,
  CardHeader,
  Button,
  AtomDatePicker,
  CardForm,
} from 'components';
import { Select as AtomSelect } from 'components';
import { InputForm } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { RiAddLine, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getIspList } from 'services/danaProgram/masterDataService';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import Tab from 'components/molecules/Tab';
import ImageDisplay from './imageDisplay';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { submitActivityReportStep1 } from 'services/danaProgram/danaProgramService';
import FileDetail from 'components/molecules/FileDetail';

const HasilPelaksanaanKegiatan = ({ setActiveTab, activeTab, datas }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let isDetail = currentPath.includes('detail');
  const [tabs, setTabs] = useState([]);

  const userSelector = useSelector((state) => state?.auth?.user || {});

  const validationSchema = yup.object().shape({
    tab2Verification: yup.boolean(),
    safeguards: yup.array().of(
      yup.object().shape({
        // Define your safeguards schema here
      })
    ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      tab2Verification: false,
      safeguards: [{}],
    },
  });

  const submit = useMutation({
    mutationKey: ['post-step-1-activity-report-review'],
    mutationFn: async (payload) => {
      const res = await submitActivityReportStep1(payload);
      return res.data.data;
    },
    onSuccess: (res) => {
      setActiveTab(activeTab + 1);
    },
  });

  const onSubmit = () => {
    let params = {
      id: sessionStorage.getItem('ActivityReportsId'),
      ...datas,
    };
    submit.mutate(params);
  };

  const { data: ispList, isFetching: isFetchingIspList } = useQuery({
    queryKey: ['get-isp-list'],
    queryFn: async () => {
      const res = await getIspList();

      return res.data.data;
    },
  });

  const [optionPurposes, setOptionPurposes] = useState([]);
  const [optionPartners, setOptionPartners] = useState([]);

  const Child = ({ datas, index }) => {
    return (
      <div className="flex gap-5 flex-col">
        <div className="border border-gray-300 rounded p-2">
          <p className="label-text text-sm">{datas?.principle_name}</p>
        </div>

        <CardForm label={`Kegiatan Utama`}>
          {datas?.activity_list?.length > 0 &&
            datas?.activity_list.map((val, i) => {
              for (let keys in val) {
                methods.setValue(`safeguards.${index}.${keys}`, val[keys]);
              }
              return (
                <div className="flex flex-col gap-6">
                  <div className="space-y-[9px]">
                    <InputForm
                      className={`py-[6px] border w-full rounded-md flex-1 border-[rgb(208,213,221)]`}
                      controllerName={`safeguards.${index}.activity`}
                      label={'Kegiatan'}
                      disabled={isDetail}
                      customValue={val?.activity}
                    />{' '}
                    <InputForm
                      controllerName={`safeguards.${index}.description`}
                      className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                      label={'Deskripsi'}
                      textArea
                      disabled={isDetail}
                      customValue={val?.description}
                    />
                    <div className="flex">
                      {val?.document?.length > 0 &&
                        val?.document?.map((dat) => {
                          return (
                            <div className="w-full">
                              <label className="label font-semibold text-[14px]">
                                <span className={`label-text`}>
                                  Dokumen Pendukung
                                </span>
                              </label>
                              <FileDetail
                                file={dat}
                                // onRemove={() => {
                                //   // methods.setValue(
                                //   //   `${alias}.activity_list.${index}.document`,
                                //   //   []
                                //   // );
                                // }}
                                hideDelete
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </CardForm>
      </div>
    );
  };

  useEffect(() => {
    for (let key in datas) {
      if (key.includes('date')) {
        const [day, month, year] = datas[key].split('/');
        const date = new Date(`${year}-${month}-${day}`);
        methods.setValue(key, date);
      } else if (key === 'safeguards') {
        const updateArrayState = () => {
          const updatedArrayState = ispList?.map((item1) => {
            const matchingItem2 = datas[key]?.find(
              (item2) => item2.principle_name === item1.deskripsi
            );

            // if (!matchingItem2) return item1;

            if (
              matchingItem2?.activity_list?.length > 0 &&
              matchingItem2?.activity_list[0]?.activity
            ) {
              return {
                ...item1,
                disable: false,
                content: <Child datas={matchingItem2} />,
              };
            }

            return {
              ...item1,
              disable: true,
            };
          });

          setTabs(sortArray(updatedArrayState));
          methods.setValue(key, datas[key]);
        };

        updateArrayState();
      } else {
        methods.setValue(key, datas[key]);
      }
    }
  }, [datas, ispList]);

  const sortArray = (data) => {
    if (data) {
      const array = data.filter((item) => item.nama !== '');
      const sortedArray = [...array].sort((a, b) => {
        const numA = a.nama.match(/\d+/)
          ? parseInt(a.nama.match(/\d+/)[0], 10)
          : '';
        const numB = b.nama.match(/\d+/)
          ? parseInt(b.nama.match(/\d+/)[0], 10)
          : '';

        return numA - numB;
      });

      return sortedArray;
    }
  };

  return (
    <div className="">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-4 mt-3">
        <FormProvider {...methods}>
          <Card>
            <CardHeader>Pelaksanaan Kegiatan</CardHeader>
            <CardBody>
              <div className="flex gap-4 flex-col">
                <InputForm
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  controllerName={`project_name`}
                  label="Proyek"
                  required={true}
                  // options={listAllEmployee ? listAllEmployee : []}

                  disabled={isDetail}
                />

                <InputForm
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  controllerName={`main_activity.main_activity_name`}
                  label="Kegiatan Utama"
                  required={true}
                  // options={listAllEmployee ? listAllEmployee : []}

                  disabled={isDetail}
                />
                <InputForm
                  controllerName="output_name"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={'Output'}
                  disabled
                />
                <div className="flex gap-4 flex-row">
                  <AtomDatePicker
                    className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                    controllerName="start_date"
                    label={'Tanggal Kegiatan'}
                    placeholder="Select a date"
                    asText={false}
                    required
                    format={'dd/MM/yyyy'}
                    disabled={isDetail}
                  />
                  <AtomDatePicker
                    className="max-h-[38px] mt-0 w-full rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none focus:ring-0 focus:border-0"
                    controllerName="end_date"
                    label={`\u00A0\u00A0\u00A0\u00A0`}
                    placeholder="Select a date"
                    asText={false}
                    format={'dd/MM/yyyy'}
                    disabled={isDetail}
                  />
                </div>
                <InputForm
                  textArea
                  controllerName="purposes"
                  required
                  label="Tujuan Kegiatan"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  disabled={isDetail}
                />
                {/* <AtomSelect
                  controllerName={`purposes`}
                  label="Tujuan Kegiatan"
                  required={true}
                  className="flex-1 text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                  multiple={true}
                  disable={isDetail}
                  options={optionPurposes}
                /> */}
                <InputForm
                  textArea
                  controllerName="partners"
                  required
                  label="Mitra/Pemangku yang dilibatkan"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  disabled={isDetail}
                />
                {/* <AtomSelect
                  controllerName={`partners`}
                  label="Mitra/Pemangku yang dilibatkan"
                  required={true}
                  className="flex-1 text-sm focus:outline-none outline-none focus:border-none remove-input-txt-border-react-select"
                  multiple={true}
                  disable={isDetail}
                  options={optionPartners}
                /> */}
                <p className={`text-md font-bold`}>Keterlibatan</p>
                <div
                  className="flex gap-4 flex-row"
                  style={{ width: `calc(100vw/2)` }}
                >
                  <div className="flex flex-col">
                    <div className="flex gap-4 flex-row items-center">
                      <InputForm
                        className={`py-[6px] border rounded-md flex-1 border-[#D0D5DD]`}
                        controllerName={`total_female`}
                        label="Perempuan"
                        disabled
                        unit="Orang"
                      />{' '}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex gap-4 flex-row items-center">
                      <InputForm
                        className={`py-[6px] border rounded-md flex-1 border-[#D0D5DD]`}
                        controllerName={`total_male`}
                        label="Laki-laki"
                        disabled
                        unit="Orang"
                      />{' '}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Hasil Kegiatan</CardHeader>
            <CardBody>
              {' '}
              <div className="flex gap-4 flex-col">
                <InputForm
                  controllerName="activity_result"
                  className={`py-[6px] border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={'Hasil Kegiatan dan atau Kemajuan'}
                  textArea={true}
                  disabled={isDetail}
                />

                <div>
                  <p className="text-sm font-semibold">
                    Dokumen Bukti Pelaksanaan Kegiatan
                  </p>
                  {datas?.document_support?.length > 0 &&
                    datas?.document_support?.map((file, index) => (
                      <FileDetail key={index} file={file} hideDelete />
                    ))}
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Safeguards yang diimplementasi</CardHeader>
            <CardBody>{tabs && <Tab tabs={tabs} />}</CardBody>
          </Card>
        </FormProvider>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>

        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={onSubmit}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HasilPelaksanaanKegiatan;
