import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from "react";
import { debounce } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

import { APPROVAL_STATUSSES } from "components/organisms/Approval2Level/constant";
import { BasicList } from "components/molecules/ListPage";
import { ConfirmationModal } from "components/molecules/Modal";
import { CustomSelect, AtomDatePicker, SearchInput } from "components";
import { SuccessToast, ErrorToast, ToastContext } from "components/atoms/Toast";

import axiosInstance from "app/interceptors";
import config from "app/config";

import useWithdrawalWorkPlan from "./hooks/useWithdrawalWorkPlan";

const RencanaPenarikanDana = () => {
  const validationSchema = yup.object().shape({
    //   name: yup.string().required("wajib"),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();

  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortMethod, setSortMethod] = useState("desc");
  const [formModal, setFormModal] = useState(false);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const [start, end] = methods.watch("exampleDate") || [null, null];

  const params = {
    searchKey: nameFilter,
    status: statusFilter !== "" ? statusFilter : "",
    start_date: startDate,
    end_date: endDate,
    page: page,
    limit: rowPage,
    orderMethod: sortMethod,
    orderBy: sortBy,
  };

  const { withdrawalFundData, paginationWithdrawalFundData, refetch } =
    useWithdrawalWorkPlan({ params });

  useEffect(() => {
    refetch();
  }, [nameFilter, statusFilter, endDate, page, rowPage, sortMethod]);

  useEffect(() => {
    refetch();
  }, []);

  function formatCurrency(amount) {
    if (isNaN(amount)) {
      return "Invalid input";
    }
    const [integerPart, decimalPart] = amount.toFixed(0).split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const formattedCurrency = `Rp. ${formattedInteger}${
      decimalPart ? `,${decimalPart}` : ""
    }`;

    return formattedCurrency;
  }

  const links = [
    {
      label: "Daftar Rencana Penarikan Dana",
    },
  ];

  useEffect(() => {
    setStartDate(formatDateRange(start));
    setEndDate(formatDateRange(end));
  }, [end]);

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || "");
      },
      [setNameFilter]
    ),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  function formatDateRange(dateString) {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return null;
    }
  }

  const tableColumns = UseMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },

      {
        id: "period",
        title: "Tanggal Pengajuan",
        dataIndex: "created_at",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "center",
        columnClassName: "w-[calc(100vw/8)] text-center overflow-hidden",
        render: (value, row) => {
          const startDate = moment
            .utc(row.created_at)
            .local()
            .format("DD/MM/YYYY");
          return (
            <div className="overflow-hidden text-center w-full max-w-[calc(100vw/8)]">
              {`${startDate}`}
            </div>
          );
        },
      },
      {
        id: "awp_project_name",
        title: "Nama Proyek",
        dataIndex: "awp_project_name",
        className: "",
        fixed: "center",
        columnClassName: "w-[calc(100vw/10)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/10)] ">
            {value}
          </div>
        ),
      },
      {
        id: "work_plan_name",
        title: "Nama Rencana Kerja Waktu Tertentu",
        dataIndex: "work_plan_name",
        className: "",
        fixed: "center",
        columnClassName: "w-[calc(100vw/6)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/6)] ">
            {value}
          </div>
        ),
      },
      {
        id: "total_withdrawal_fund",
        title: "Total Nilai Penarikan",
        dataIndex: "total_withdrawal_fund",
        className: "",
        columnClassName:
          "w-[calc(100vw/8)] text-center whitespace-break-spaces",
        sortable: true,
        fixed: "center",
        render: (value) => {
          let val = formatCurrency(value);
          return (
            <div className="overflow-hidden text-center text-ellipsis w-full max-w-[calc(100vw/8)]">
              {val}
            </div>
          );
        },
      },
      {
        id: "_id",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <MdOutlineRemoveRedEye
                    className="text-gray-600 cursor-pointer flex-1"
                    size={28}
                    onClick={() => {
                      localStorage.setItem(
                        "withdrawalWorkPlan",
                        value.toString()
                      );
                      navigate("/rencana-penarikan-dana/detail/");
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, []);

  const onCreate = () => {
    navigate("/rencana-penarikan-dana/add");
  };

  const deleteItemHandler = async () => {
    let payload = {
      id: selectedItem?._id,
    };

    try {
      const response = await axiosInstance.delete(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/delete`,
        {
          data: payload,
        }
      );
      const data = response.data;

      if (data?.code === 200) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data berhasil dihapus!",
          "Data yang anda pilih berhasil dihapus."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
        refetch();
      }
    } catch (error) {
      if (error?.response?.data) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data gagal dihapus!",
          error?.response?.data?.message || error?.response?.data?.[0]?.message
        );
        setShowToast((prev) => ({ ...prev, error: true }));
        refetch();
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={formModal}
        onClose={() => setFormModal(false)}
        onSubmit={deleteItemHandler}
      >
        <div>
          <div className="text-gray-900 text-lg font-semibold">
            Apakah Anda yakin akan menghapus draft ini?
          </div>
        </div>
      </ConfirmationModal>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={"Daftar Rencana Penarikan Dana Waktu Tertentu"}
        columns={tableColumns}
        dataSource={withdrawalFundData}
        onCreate={onCreate}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        customTextAddButton="Tambah"
        pagination={paginationWithdrawalFundData}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-2 flex">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
              <CustomSelect
                optionsData={APPROVAL_STATUSSES}
                placeholder="Status"
                inputClassName="5px"
                className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                onChange={onChangeStatus}
              />
              <div className="flex-1">
                <AtomDatePicker
                  className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  controllerName="exampleDate"
                  label={null}
                  placeholder="Periode Kegiatan"
                  asText={false}
                  isRangeDatePicker={true}
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default RencanaPenarikanDana;
