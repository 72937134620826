import config from "app/config";
import axiosInstance from "app/interceptors";
import axios from "axios";

export const getSpecificTimeWorkPlan = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/spesific-work-plan/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""
    }${
      params?.startDate !== undefined && params?.startDate !== null
        ? `&start_date=${params?.startDate} `
        : ""
    }${
      params?.endDate !== undefined && params?.endDate !== null
        ? `&end_date=${params?.endDate} `
        : ""
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ""
    }${params?.status !== undefined ? `&status=${params?.status}` : ""}`
  );
};

export const getSpecificTimeWorkPlanDetail = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/spesific-work-plan/detail?id=${id}`
  );
};

export const submitSpecificTimeWorkPlan = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/spesific-work-plan/submit`,
    params
  );
};

export const getWithdrawalFundPlan = (params) => {
  return axiosInstance.get(
    `${
      config.BASE_URL
    }/api/dana-program/v1/cms/withdrawal-work-plan/list?limit=${
      params?.limit
    }&page=${params?.page}${
      params?.searchKey !== undefined ? `&search=${params?.searchKey}` : ""
    }${params?.start_date !== null ? `&start_date=${params?.start_date}` : ""}${
      params?.end_date !== null ? `&end_date=${params?.end_date}` : ""
    }${params?.orderBy !== undefined ? `&order_by=${params?.orderBy}` : ""}${
      params?.orderMethod !== undefined
        ? `&order_method=${params?.orderMethod}`
        : ""
    }${params?.status !== undefined ? `&status=${params?.status}` : ""}`
  );
};

export const getWithdrawalFundPlanDetail = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/withdrawal-work-plan/detail?id=${params.detailId}`
  );
};

export const submitWithdrawalFundPlan = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/withdrawal-work-plan/submit`,
    params
  );
};

export const approvalWithdrawalFundPlan = (params) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/withdrawal-work-plan/approval`,
    params
  );
};

export const approvalEligiblityWithdrawalFundPlan = ({ queryKey }) => {
  const [, withdrawalWorkPlanId] = queryKey;

  return axiosInstance.post(
    `${config.BASE_URL}/api/dana-program/v1/cms/withdrawal-work-plan/approval-eligiblity`,
    {
      id: withdrawalWorkPlanId,
    }
  );
};

export const getProject = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/investment-plan`,
    { params }
  );
};

export const getAWP = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/provider/awp`,
    { params }
  );
};

export const getAWPActivity = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/provider/activity`,
    { params }
  );
};

export const getAWPDetail = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/provider/detail`,
    { params }
  );
};

export const getAWPBudgetAccount = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/dana-program/v1/cms/provider/budget-account`,
    { params }
  );
};
