import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Fragment,
} from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import {
  AiFillFilePdf,
  AiOutlineEye,
  AiFillFileExcel,
  AiFillFileImage,
  AiFillFileWord,
} from "react-icons/ai";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsEyeFill } from "react-icons/bs";

const defaultClassName =
  "bg-white border-[1px] border-dashed border-[#D0D5DD] rounded-xl py-4 px-6 min-h-[130px] flex items-center justify-center !mt-2 cursor-pointer mb-2";
const errorClassName = "!border-error-500";

const filePreview = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 100,
  height: 100,
  cursor: "pointer",
  backgroundColor: "rgba(0,0,0,0.7)",
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const PreviewDiv = ({ files, name, setFiles, setValue }) => {
  if (!files?.length) return null;
  return files.map((file, index) => {
    return (
      <div
        key={file.name}
        className="flex items-center w-full p-4 gap-8 border rounded-lg"
      >
        {file?.type?.includes("image") ? (
          <AiFillFileImage size={40} fill="#00BD52" />
        ) : file?.type?.includes("application/pdf") ? (
          <AiFillFilePdf size={40} fill="#00BD52" />
        ) : file?.type?.includes("text/csv") ||
          file?.type?.includes("application/vnd.ms-excel") ||
          file?.type?.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) ? (
          <AiFillFileExcel size={40} fill="#00BD52" />
        ) : file?.type?.includes("application/msword") ||
          file?.type?.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ? (
          <AiFillFileWord size={40} fill="#00BD52" />
        ) : (
          <div
            key={file.name}
            style={filePreview}
            onClick={() => window.open(file.url, "_blank")}
          >
            <AiOutlineEye className="text-white cursor-pointer" size={20} />
          </div>
        )}
        <div className="flex flex-col flex-1">
          <span className="text-base font-medium text-gray-700">
            {file.name ?? "-"}
          </span>
          <span className="text-xs text-gray-700">
            ({formatBytes(file.size)})
          </span>
        </div>
        <div className="flex gap-2">
          <a href={file.url} target="_blank" rel="noreferrer">
            <BsEyeFill
              size={18}
              fill="#667085"
              className="hover:fill-sky-600 cursor-pointer"
            />
          </a>
          <RiDeleteBin6Line
            onClick={() => {
              const newData = files.filter(
                (img) => img.name !== files[index].name
              );
              setFiles(newData);
              setValue(name, newData, { shouldValidate: true });
            }}
            size={18}
            fill="#667085"
            className="hover:fill-red-600 cursor-pointer"
          />
        </div>
      </div>
    );
  });
};

const CustomDropzoneForm = ({
  maxSize = 10,
  maxFiles = 1,
  multiple = false,
  acceptedFile = {
    "image/png": [],
    "image/jpeg": [],
    "application/pdf": [],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [],
    "application/msword": [],
  },
  informationText = "PNG, JPG, PDF, Docx up to 10MB",
  name,
  defaultValue,
  disabled = false,
  errorMessage,
}) => {
  const { control, setValue, setError, clearErrors, getValues } =
    useFormContext();

  const [files, setFiles] = useState([]);

  const maxSizeFile = useMemo(() => maxSize * 1000000, [maxSize]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setFiles((current) => {
        return defaultValue;
      });
      setValue(name, defaultValue, {
        shouldValidate: true,
      });
    }
  }, []);

  const maxSizeFileValidation = useCallback(
    (file) => {
      if (file.size > maxSizeFile) {
        setError(name, {
          type: "manual",
          message: `File maksimal berukuran ${maxSize}MB`,
        });
        return {
          code: "maxSizeFileValidation",
          message: `File maksimal berukuran ${maxSize}MB`,
        };
      }
    },
    [maxSizeFile, maxSize, name, setError]
  );

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      clearErrors([name]);
      if (!fileRejections.length) {
        setFiles((current) => {
          return [...current, ...acceptedFiles].map((file) =>
            Object.assign(file, {
              url: file instanceof File ? URL.createObjectURL(file) : file.url,
            })
          );
        });
        const getFiles = getValues(name);
        setValue(name, [...getFiles, ...acceptedFiles], {
          shouldValidate: true,
        });
      } else {
        if (fileRejections[0].errors[0].message)
          setError(name, {
            type: "manual",
            message: fileRejections[0].errors[0].message,
          });
      }
    },
    [name, setValue, clearErrors, setError]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFile,
    multiple,
    maxFiles,
    validator: maxSizeFileValidation,
    disabled: disabled,
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.url));
  }, [files]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <Fragment>
            {multiple ? (
              <>
                {files.length + 1 <= maxFiles && (
                  <div
                    className={classNames(...defaultClassName.split(" "), {
                      [errorClassName]: invalid,
                    })}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div className="flex flex-col items-center space-y-1">
                        <FiUploadCloud className="text-gray-600" size={24} />
                        <p className="text-gray-600 text-sm !mt-3">
                          Lepaskan file disini
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center space-y-1">
                        <FiUploadCloud className="text-gray-600" size={24} />
                        <p className="text-gray-600 text-sm !mt-3">
                          <b className="text-primary-700">Klik untuk upload</b>{" "}
                          atau seret dan lepas kesini
                        </p>
                        <p className="text-gray-600 text-sm">
                          {informationText}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <div className="w-full space-y-3 mt-4">
                  <PreviewDiv
                    files={files}
                    name={name}
                    setFiles={setFiles}
                    setValue={setValue}
                  />
                </div>
              </>
            ) : files.length < 1 ? (
              <div
                className={classNames(...defaultClassName.split(" "), {
                  [errorClassName]: invalid,
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className="flex flex-col items-center space-y-1">
                    <FiUploadCloud className="text-gray-600" size={24} />
                    <p className="text-gray-600 text-sm !mt-3">
                      Lepaskan file disini
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center space-y-1">
                    <FiUploadCloud className="text-gray-600" size={24} />
                    <p className="text-gray-600 text-sm !mt-3">
                      <b className="text-primary-700">Klik untuk upload</b> atau
                      seret dan lepas kesini
                    </p>
                    <p className="text-gray-600 text-sm">{informationText}</p>
                  </div>
                )}
              </div>
            ) : (
              <PreviewDiv
                files={files}
                name={name}
                setFiles={setFiles}
                setValue={setValue}
              />
            )}
            {invalid && (
              <div className="text-xs text-error-600">
                {error?.message || errorMessage}
              </div>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default CustomDropzoneForm;
