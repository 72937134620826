/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Button, CustomSelect } from "components";
import classNames from "classnames";
import { isEqual } from "lodash";
import usePreviousValue from "beautiful-react-hooks/usePreviousValue";

const defaultStep = 1;

const paginationObject = {
  page: 1,
  limit: 10,
  total: 0,
  previous_pages: null,
  next_pages: null,
};

const rowPerPageOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const generatePageNumbers = (length) => {
  if (length) return Array.from(Array(Number(length)), (_, x) => x + 1);
  return [];
};

const Pagination = ({
  pagination = paginationObject,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const [rowPerPage, setRowPerPage] = useState({ value: 10, label: "10" });
  const [currentPage, setCurrentPage] = useState(1);
  const prevCurrentPage = usePreviousValue(currentPage);

  const rowsPerPage = useMemo(() => rowPerPage, [rowPerPage]);
  const changeRowsPerPage = useCallback(
    (page) => {
      setRowPerPage(page);
      setCurrentPage(1);
      if (onChangeRowsPerPage) onChangeRowsPerPage(page.value);
    },
    [onChangeRowsPerPage]
  );

  const pageNumbers = generatePageNumbers(
    Math.round(Math.ceil(pagination?.total / rowPerPage.value))
  );

  const addPagination = useCallback(
    (s, f) => {
      let elms = [];
      var func = function (x) {
        setCurrentPage(x);
      };
      for (let index = s; index < f; index += 1) {
        elms.push(
          <Button
            key={index}
            size="sm"
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: currentPage === index }
            )}
            onClick={() => func(index)}
          >
            {index}
          </Button>
        );
      }
      return elms;
    },
    [currentPage]
  );

  const renderPaginationLabel = useCallback(() => {
    if (pageNumbers.length < defaultStep * 7) {
      return addPagination(1, pageNumbers.length + 1, currentPage);
    } else if (currentPage < defaultStep * 3) {
      return (
        <>
          {addPagination(1, defaultStep * 4, currentPage)}
          <Button
            size="sm"
            className="bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold"
            disabled
          >
            ..
          </Button>
          {addPagination(
            pageNumbers.length - 2,
            pageNumbers.length + 1,
            currentPage
          )}
        </>
      );
    } else if (currentPage > pageNumbers.length - defaultStep * 2) {
      return (
        <>
          {addPagination(1, defaultStep * 4, currentPage)}
          <Button
            size="sm"
            className="bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold"
            disabled
          >
            ..
          </Button>
          {addPagination(
            pageNumbers.length - 2,
            pageNumbers.length + 1,
            currentPage
          )}
        </>
      );
    } else {
      return (
        <>
          <Button
            size="sm"
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: currentPage === 1 }
            )}
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            1
          </Button>
          <Button
            size="sm"
            className="bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold"
            disabled
          >
            ..
          </Button>
          {addPagination(
            currentPage - defaultStep,
            currentPage + (defaultStep + 1),
            currentPage
          )}
          <Button
            size="sm"
            className="bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold"
            disabled
          >
            ..
          </Button>
          <Button
            size="sm"
            className={classNames(
              "bg-white hover:bg-white text-gray-800 border-gray-300 text-sm font-semibold",
              { [`!border-black`]: currentPage === pageNumbers.length }
            )}
            onClick={() => {
              setCurrentPage(pageNumbers.length);
            }}
          >
            {pageNumbers.length}
          </Button>
        </>
      );
    }
  }, [currentPage, pageNumbers, addPagination]);

  const renderPaginationText = useCallback(() => {
    return (
      <p className="mb-0 text-gray-600 text-sm">{`${
        (currentPage - 1) * rowsPerPage.value || 1
      } - ${
        currentPage <= 1 && pagination?.total <= rowsPerPage.value
          ? pagination?.total
          : currentPage * rowsPerPage.value
      } data dari ${pagination?.total ?? 0} data.`}</p>
    );
  }, [rowsPerPage.value, currentPage, pagination.total]);

  useEffect(() => {
    if (!isEqual(prevCurrentPage, currentPage)) {
      if (onChangePage) onChangePage(currentPage);
    }
  }, [currentPage, prevCurrentPage]);

  return (
    <div className="lg:flex sm:block items-center py-4 px-6 justify-between border-t-[1px]">
      <div className="flex space-x-4 items-center">
        <p className="mb-0 text-gray-600 text-sm">Tampilkan Data</p>
        <CustomSelect
          value={rowsPerPage}
          optionsData={rowPerPageOptions}
          onChange={changeRowsPerPage}
          menuPortalTarget={document.body}
        />
        {renderPaginationText()}
      </div>
      <div className="btn-group">
        {pageNumbers?.length > 0 && (
          <Button
            size="sm"
            className="prevbtn bg-white hover:bg-white hover:text-gray-700 text-gray-700 border-gray-300 text-sm font-semibold btn-outline"
            startIcon={<BsArrowLeftShort size={20} />}
            disabled={currentPage === 1}
            onClick={() => {
              setCurrentPage((curr) => curr - 1);
            }}
          >
            Previous
          </Button>
        )}
        {renderPaginationLabel()}
        {pageNumbers?.length > 0 && (
          <Button
            size="sm"
            className="bg-white hover:bg-white hover:text-gray-700 text-gray-700 border-gray-300 text-sm font-semibold btn-outline"
            endIcon={<BsArrowRightShort size={20} />}
            disabled={currentPage === pageNumbers.length}
            onClick={() => {
              setCurrentPage((curr) => curr + 1);
            }}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(Pagination);
