import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  RiCheckLine,
  RiComputerLine,
  RiBankLine,
  RiCoinsLine,
  RiApps2Line,
  RiShieldCheckLine,
  RiHistoryLine,
  RiArrowLeftLine,
} from "react-icons/ri";
import { BsClock, BsEye } from "react-icons/bs";
import { useNavigate } from "react-router";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import useVerification from "../hooks/useVerification";
import { GeneralProfileProvider } from "./ProfileUmum/hooks/generalProfileState";

const VerificationDetail = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  // const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  let _id = JSON.parse(sessionStorage.getItem("idData")).id;

  const { DetailData, refetchDetailData } = useVerification({
    search: keyword,
    status: typeFilter,
    page,
    limit,
    id: _id,
  });

  useEffect(() => {
    refetchDetailData();
  }, []);

  const [moduleItems] = useState([
    {
      icon: <RiComputerLine className="text-[#E62E05]" />,
      label: "profile umum",
      name: "general_profile",
      path: "/verification/detail/profile-umum",
      background: "bg-[#FFE6D5]",
      backgroundOuter: "bg-[#FFF4ED]",
      status: 1,
    },
    {
      icon: <RiBankLine className="text-[#1570EF]" />,
      label: "profile organisasi",
      name: "organization_profile",
      path: "/verification/detail/profile-organisasi",
      background: "bg-[#D1E9FF]",
      backgroundOuter: "bg-[#EFF8FF]",
      status: 1,
    },
    {
      icon: <RiCoinsLine className="text-[#099250]" />,
      label: "sumber daya",
      name: "resource",
      path: "/verification/detail/sumber-daya",
      background: "bg-[#D3F8DF]",
      backgroundOuter: "bg-[#EDFCF2]",
      status: 1,
    },
    {
      icon: <RiComputerLine className="text-[#CA8504]" />,
      label: "profile keuangan",
      name: "financial_profile",
      path: "/verification/detail/profil-keuangan",
      background: "bg-[#FEF7C3]",
      backgroundOuter: "bg-[#FEFBE8]",
      status: 2,
    },
    {
      icon: <RiApps2Line className="text-[#6938EF]" />,
      label: "pengalaman Program",
      name: "program_experience",
      path: "/verification/detail/pengalaman-program",
      background: "bg-[#EBE9FE]",
      backgroundOuter: "bg-[#F4F3FF]",
      status: 1,
    },
    {
      icon: <RiShieldCheckLine className="text-[#DD2590]" />,
      label: "safeguards",
      name: "safe_guards",
      path: "/verification/detail/safeguards",
      background: "bg-[#FCE7F6]",
      backgroundOuter: "bg-[#FDF2FA]",
      status: 2,
    },
  ]);

  const actionStatus = {
    NEED_APPROVAL: "Perlu Diverifikasi",
    VERIFIED: "Telah Diverifikasi",
  }

  const links = [
    {
      label: "Daftar LEMTARA",
      path: "/verification",
    },
    {
      label: "Verifikasi",
    },
  ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <Card>
        <CardHeader>
          <div>{DetailData && DetailData?.lemtara_name}</div>
        </CardHeader>
        <CardBody>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {_.map(
              moduleItems,
              ({
                icon,
                label,
                name,
                path,
                background,
                backgroundOuter,
                status,
              }) => {
                // let keyAction = actionStatus.find(
                //   (element) => element.status === DetailData[`${name}`]?.status
                // );
                return (
                  <div className="bg-white shadow-xl rounded-xl">
                    <div className="p-4">
                      <div className="flex items-center gap-2">
                        <div className={`${backgroundOuter} p-2 rounded-full`}>
                          <div className={`${background} p-2 rounded-full`}>
                            {icon}
                          </div>
                        </div>
                        <span>{_.upperFirst(label)}</span>
                      </div>
                    </div>
                    <div
                      className={`badge items-center gap-2 mx-6
                    ${
                      DetailData &&
                      DetailData[`${name}`]?.status === "NEED_APPROVAL" &&
                      "bg-warning-50 text-warning-700"
                    }
                    ${
                      DetailData &&
                      DetailData[`${name}`]?.status === "VERIFIED" &&
                      "bg-primary-50 text-primary-700"
                    }
                  `}
                    >
                      <span>
                        {DetailData &&
                          DetailData[`${name}`]?.status === "NEED_APPROVAL" && (
                            <BsClock />
                          )}
                        {DetailData &&
                          DetailData[`${name}`]?.status === "VERIFIED" && (
                            <RiCheckLine />
                          )}
                      </span>
                      <span>
                        {actionStatus[DetailData?.[`${name}`]?.status] ?? DetailData?.[`${name}`]?.status}
                      </span>
                    </div>
                    <div className="border-b py-2" />
                    <div className="px-4 py-4">
                      <div className="text-right">
                        <button
                          onClick={() => navigate(path)}
                          className={`py-2 px-4 rounded-lg font-semibold text-[12px]
                        ${
                          DetailData &&
                          DetailData[`${name}`]?.status === "NEED_APPROVAL" &&
                          "bg-[#00BD52] text-white"
                        }
                        ${
                          DetailData &&
                          DetailData[`${name}`]?.status === "VERIFIED" &&
                          "border-[1px]"
                        }
                        `}
                        >
                          {DetailData &&
                            DetailData[`${name}`]?.status === "NEED_APPROVAL" &&
                            "Review"}
                          {DetailData &&
                            (DetailData[`${name}`]?.status === "APPROVED" || DetailData[`${name}`]?.status === "VERIFIED") && (
                              <div className="flex items-center gap-2">
                                <BiPencil />
                                Ubah Verifikasi
                              </div>
                            )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => navigate('/verification')}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
          >
            Kirim Hasil Verifikasi
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerificationDetail;
