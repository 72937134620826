import React, {
  useCallback,
  useEffect,
  useMemo as UseMemo,
  useState,
  useContext,
} from "react";
import { RiEyeLine } from "react-icons/ri";
import { FaRegTrashAlt } from "react-icons/fa";
import { debounce } from "lodash";
import { useNavigate } from "react-router";

import { BasicList } from "components/molecules/ListPage";
import { Button, CustomSelect, AtomDatePicker, SearchInput } from "components";
import useTimeWorkPlan from "./hooks/useTimeWorkPlan";
import { BsDot } from "react-icons/bs";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import moment from "moment";
import { ConfirmationModal } from "components/molecules/Modal";
import axios from "axios";
import { SuccessToast, ErrorToast, ToastContext } from "components/atoms/Toast";
import config from "app/config";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateWorkPlan } from "app/actions";
import axiosInstance from "app/interceptors";

const RencanaKerjaWaktu = () => {
  const validationSchema = yup.object().shape({
    //   name: yup.string().required("wajib"),
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [rowPage, setRowPage] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortMethod, setSortMethod] = useState("desc");
  const [selectedItem, setSelectedItem] = useState(null);
  const [formModal, setFormModal] = useState(false);
  const {
    showToast,
    setShowToast,
    initialShowToast,
    showToastMessage,
    toastMessage,
    toastDescription,
  } = useContext(ToastContext);

  const [start, end] = methods.watch("exampleDate") || [null, null];

  const params = {
    searchKey: nameFilter,
    status: statusFilter,
    startDate: startDate,
    endDate: endDate,
    page: page,
    limit: rowPage,
    orderMethod: sortMethod,
    orderBy: sortBy,
  };

  const { timeWorkPlanData, paginationtimeWorkPlanData, refetch } =
    useTimeWorkPlan({ params });

  useEffect(() => {
    refetch();
  }, [nameFilter, statusFilter, endDate, page, rowPage, sortMethod]);

  useEffect(() => {
    refetch();
  }, []);

  const storeId = (id) => {
    localStorage.setItem("specificWorkPlanId", id.toString());
  };

  const handleOpenDetail = (id) => {
    navigate(`/rencana-kerja-waktu/${id}`);
  };

  const handleOpenRevision = (id) => {
    storeId(id);
    navigate("/rencana-kerja-waktu/revision");
  };

  const links = [
    {
      label: "Daftar Rencana Kerja Waktu Tertentu",
    },
  ];

  const paginator = UseMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) => {
        setNameFilter(event?.target?.value || "");
      },
      [setNameFilter]
    ),
    1000
  );

  const onChangeStatus = useCallback((obj) => {
    setStatusFilter(obj.value || undefined);
  }, []);

  useEffect(() => {
    setStartDate(formatDateRange(start));
    setEndDate(formatDateRange(end));
  }, [end]);

  function formatDateRange(dateString) {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return null;
    }
  }

  const tableColumns = UseMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[50px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "work_plan_name",
        title: "Nama Rencana Kerja Waktu Tertentu",
        dataIndex: "work_plan_name",
        className: "",
        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)] ">
            {value}
          </div>
        ),
      },
      {
        id: "period",
        title: "Periode Kegiatan",
        dataIndex: "start_date",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        fixed: "center",
        columnClassName: "w-[calc(100vw/7)] text-ellipsis overflow-hidden",
        render: (value, row) => {
          const startDate = moment
            .utc(row.start_date)
            .local()
            .format("DD/MM/YYYY");
          const endDate = moment.utc(row.end_date).local().format("DD/MM/YYYY");
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[calc(100vw/7)]">
              {`${startDate} - ${endDate}`}
            </div>
          );
        },
      },
      {
        id: "awp_project_name",
        title: "Kegiatan Utama",
        dataIndex: "awp_project_name",
        className: "",
        columnClassName:
          "w-[calc(100vw/7)] text-center whitespace-break-spaces",
        sortable: true,
        fixed: "center",
        render: (value) => (
          <div className="overflow-hidden text-center text-ellipsis w-full max-w-[calc(100vw/7)]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "_id",
        className: "",
        sortable: true,
        columnClassName: "w-[250px] text-center text-ellipsis overflow-hidden",
        fixed: "center",
        render: (value, data, index) => {
          return value ? (
            <>
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <div className="space-x-3 flex w-full">
                  <MdOutlineRemoveRedEye
                    className="text-gray-600 cursor-pointer flex-1"
                    size={20}
                    onClick={() => handleOpenDetail(value)}
                  />
                </div>
                <div className="space-x-3 justify-center flex w-full">
                  <Button
                    type="button"
                    className={`
                        text-white w-40
                          bg-[#00BD52]
                        `}
                    onClick={() => {
                      handleOpenRevision(value);
                    }}
                  >
                    Revisi
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
    ];
  }, []);

  const onCreate = () => {
    navigate("/rencana-kerja-waktu/add");
    // localStorage.setItem("currentStep", JSON.stringify(1));
  };

  const deleteItemHandler = async () => {
    let payload = {
      id: selectedItem?._id,
    };

    try {
      const response = await axiosInstance.delete(
        `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/delete`,
        {
          data: payload,
        }
      );
      const data = response.data;

      if (data?.code === 200) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data berhasil dihapus!",
          "Data yang anda pilih berhasil dihapus."
        );
        setShowToast((prev) => ({ ...prev, success: true }));
        refetch();
      }
    } catch (error) {
      if (error?.response?.data) {
        setFormModal(false);
        setSelectedItem(null);
        showToastMessage(
          "Data gagal dihapus!",
          error?.response?.data?.message || error?.response?.data?.[0]?.message
        );
        setShowToast((prev) => ({ ...prev, error: true }));
        refetch();
      }
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={formModal}
        onClose={() => setFormModal(false)}
        onSubmit={deleteItemHandler}
      >
        <div>
          <div className="text-gray-900 text-lg font-semibold">
            Apakah Anda yakin akan menghapus draft ini?
          </div>
        </div>
      </ConfirmationModal>
      {showToast.success && (
        <SuccessToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      {showToast.error && (
        <ErrorToast
          onClose={() => setShowToast(initialShowToast)}
          message={toastMessage}
          message_description={toastDescription}
        />
      )}
      <BasicList
        links={links}
        title={"Daftar Rencana Kerja Waktu Tertentu"}
        columns={tableColumns}
        dataSource={timeWorkPlanData}
        onCreate={onCreate}
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        setSortBy={setSortBy}
        customTextAddButton="Tambah"
        pagination={paginationtimeWorkPlanData}
        onChangePage={(page) => setPage(page)}
        onChangeRowsPerPage={(row) => setRowPage(row)}
        customFilter={
          <FormProvider {...methods}>
            <div className="space-x-2 flex">
              <SearchInput
                controllerName={`search`}
                placeholder="Cari"
                onChange={onSearch}
              />
              <div className="flex-1">
                <AtomDatePicker
                  className="!mt-0 flex-1 rounded-md border-gray-200 border-solid text-sm outline-none focus:outline-none items-center self-center remove-input-txt-border-react-select"
                  controllerName="exampleDate"
                  label={null}
                  placeholder="Periode Kegiatan"
                  asText={false}
                  isRangeDatePicker={true}
                />
              </div>
            </div>
          </FormProvider>
        }
      />
    </div>
  );
};

export default RencanaKerjaWaktu;
