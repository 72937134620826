import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
  InputFormUpload,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiSave3Line,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from "react-icons/ri";
import axios from "axios";
import config from "app/config";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { fileUpload } from "services/danaProgram/fileService";
import _ from "lodash";
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from "../../../context/InvestmentPlanContext";
import axiosInstance from "app/interceptors";
import FileDetail from "components/molecules/FileDetail";

const validationSchema = yup.object().shape({
  // benfitValue: yup.string().required('wajib'),
});

const AddSupportFile = () => {
  let isAdd = useLocation()?.pathname.includes("add");
  const navigate = useNavigate();
  const location = useLocation();
  const { idInvestment, idProposal, setCurrentStep, dataInvestment, type } =
    useInvestmentPlanContext();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSave = async (data) => {
    try {
      data.id = idInvestment;

      let step_4 = [];

      for (let file of data.supportFile) {
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        const payloadPerFile = {
          fileId: resDataFile.id,
          fileName: resDataFile.name,
          fileSize: resDataFile.size,
          mimeType: resDataFile.mimeType,
          path: resDataFile.url,
        };

        step_4.push(payloadPerFile);
      }

      data.step_4 = step_4;

      await axiosInstance
        .post(
          `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/fourth-step`,
          data
        )
        .then((res) => {
          navigate("/investment-plan/");
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    if (type === "show") {
      navigate("/investment-plan/");
    } else {
      try {
        data.id = idInvestment;

        let step_4 = [];

        for (let file of data.supportFile) {
          const fileFormData = new FormData();
          fileFormData.append("file", file);
          const resFile = await fileUpload(fileFormData);
          const resDataFile = await resFile.data.data;

          const payloadPerFile = {
            fileId: resDataFile.id,
            fileName: resDataFile.name,
            fileSize: resDataFile.size,
            mimeType: resDataFile.mimeType,
            path: resDataFile.url,
          };

          step_4.push(payloadPerFile);
        }

        data.step_4 = step_4;
        console.log(data);
        await axiosInstance
          .post(
            `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/fourth-step`,
            data
          )
          .then((res) => {
            navigate("/investment-plan/");
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
        {/* <Breadcrumbs items={links} /> */}
        {/* <ProgressStepForm items={stepForm} step={step} /> */}
        <div className="flex flex-col gap-4 mt-3"></div>
        <Card>
          <CardHeader>
            <div>Dokumen Pendukung</div>
            <label className="font-normal text-sm text-gray-500">*Dokumen yang diupload berupa dokumen investment plan atau dokumen pendukung lainnya</label>
          </CardHeader>
          <CardBody>
            <InputFormUpload
              controllerName={"supportFile"}
              maxSize={102400}
              label="Upload Dokumen Pendukung"
              isMulti
              listFiles={dataInvestment?.step_4 ?? []}
              hideSectionUpload={type === "show"}
              disableDelete={type === "show"}
            />
          </CardBody>
        </Card>

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => setCurrentStep({ currentStep: "3" })}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {isAdd && (
            <div className="block md:flex items-center justify-between gap-2">
              {/* <Button
              type="button"
              className="text-black border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={methods.handleSubmit(onSave)}
              disabled={type === "show"}
            >
              <RiSave3Line className="text-black" />
              Simpan & Draft
            </Button> */}
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default AddSupportFile;
