import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  DateInput,
  InputForm,
} from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiSave3Line,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from 'react-icons/ri';
import axios from 'axios';
import config from 'app/config';
import { useForm, useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  InvestmentPlanProvider,
  useInvestmentPlanContext,
} from '../../../context/InvestmentPlanContext';
import axiosInstance from 'app/interceptors';

const validationSchema = yup.object().shape({
  // benfitValue: yup.string().required('wajib'),
});

const AddResultChain = () => {
  const navigate = useNavigate();
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [indikator, setIndikator] = useState([]);
  const [parameter, setParameter] = useState([]);
  const [outcome, setOutcome] = useState('');
  const [output, setOutput] = useState('');
  const [sub_output, setSubOutput] = useState('');
  const [percentage, setPercentage] = useState('');
  const [name, setParamName] = useState('');
  const { idInvestment, idProposal, setCurrentStep, dataInvestment, type } =
    useInvestmentPlanContext();
  const [openedRs, setOpenedRs] = useState([]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      indikator: [],
    },
  });
  const location = useLocation();
  const { fields: indikatorField } = useFieldArray({
    control: methods.control,
    name: 'indikator',
  });

  const step = Number(location.pathname.split('/')[3]);

  const links = [
    {
      label: 'Daftar Investmen Plan',
    },
    {
      label: 'Ajukan Project',
    },
  ];

  const getParameterIndicator = async (name) => {
    return new Promise(async (resolve, reject) => {
      try {
        const reqIndicator = {
          generalSearch:
            name?.replace(/^#(\d+)\s/, '') || name?.replace(/^#(\d+)\s/, ''),
          pageNumber: 1,
          pageSize: 10,
        };
        const params = await axiosInstance.post(
          `${config.BASE_URL}/api/master/v1/Indicator/datatable`,
          reqIndicator
        );

        let parameter = await params?.data?.data?.data
          ?.map((el) =>
            el.indicator
              .map((indicator) => indicator.parameter)
              .flatMap((e) => [...e])
          )
          .flatMap((e) => [...e]);

        resolve(parameter);
      } catch (err) {
        reject(err);
      }
    });
  };

  const fetchToc = async () => {
    const res = await axiosInstance.get(
      `${config.BASE_URL}/api/perhimpunan-dana/v1/integration/daprog/proposal/${idProposal}/toc`
    );

    let data = await res?.data?.result;

    let tempIndikator = data?.map((indikator, idx) => {
      return {
        ...indikator,
        isChecked: false,
      };
    });

    for (let [i, data] of tempIndikator.entries()) {
      let res = await getParameterIndicator(
        data?.outcome?.name || data?.output?.name
      );
      tempIndikator[i] = {
        ...data,
        parameter: res,
      };
    }

    methods.reset({
      indikator: tempIndikator,
    });
  };

  const toggleAccordion = (key) => {
    setOpenedRs((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const toggleActive = (key) => {
    let temp = [...indikatorField];
    temp[key].isChecked = !temp[key].isChecked;

    methods.setValue('indikator', temp);
  };

  let step_3 = [
    {
      outcome,
      output,
      sub_output,
      parameter: [name],
    },
  ];

  const onSave = async (data) => {
    data.id = idInvestment;
    let arr = [];
    data.indikator.map((el, i) => {
      if (el.isChecked) {
        arr.push({
          indicator: el?.indicator?.name ?? null,
          indicator_id: el?.indicator?.id ?? null,
          outcome: el?.outcome?.name ?? null,
          outcome_id: el?.outcome?.id ?? null,
          output: el?.output?.name ?? null,
          output_id: el?.output?.id ?? null,
          sub_output: el?.suboutput?.name ?? null,
          sub_output_id: el?.suboutput?.id ?? null,
          percentage: percentage ? percentage : el?.percentage,
          parameter: el?.parameter ?? null,
        });
      }
    });

    data.step_3 = arr;

    delete data['indikator'];

    try {
      axiosInstance
        .post(
          `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/third-step`,
          data
        )
        .then((res) => {
          navigate('/investment-plan/');
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (data) => {
    if (type === 'show') {
      setCurrentStep({ currentStep: '4' });
    } else {
      data.id = idInvestment;
      let arr = [];
      data.indikator.map((el, i) => {
        if (el.isChecked) {
          arr.push({
            indicator: el?.indicator?.name ?? null,
            indicator_id: el?.indicator?.id ?? null,
            outcome: el?.outcome?.name ?? null,
            outcome_id: el?.outcome?.id ?? null,
            output: el?.output?.name ?? null,
            output_id: el?.output?.id ?? null,
            sub_output: el?.suboutput?.name ?? null,
            sub_output_id: el?.suboutput?.id ?? null,
            percentage: percentage ? percentage : el?.percentage,
            parameter: el?.parameter ?? null,
          });
        }
      });

      data.step_3 = arr;

      delete data['indikator'];

      try {
        await axiosInstance
          .post(
            `${config.BASE_URL}/api/dana-program/v1/cms/investment-plan/store/third-step`,
            data
          )
          .then((res) => {
            setCurrentStep({ currentStep: '4' });
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(dataInvestment?.step_3) && type !== 'create') {
      let temp = dataInvestment?.step_3?.map((el, i) => ({
        indicator: { name: el.indicator ?? null },
        outcome: { name: el.outcome ?? null },
        output: { name: el.output ?? null },
        suboutput: { name: el.suboutput ?? null },
        percentage: percentage ? percentage : el.percentage,
        parameter: el?.parameter ?? null,
        isChecked: true,
      }));
      methods.reset({
        indikator: temp,
      });
    } else {
      fetchToc();
    }
  }, [dataInvestment, type]);

  return (
    <div className="sm:max-w-[480px] md:max-w-[608px] lg:max-w-[864px] xl:max-w-[81vw]">
      {/* <Breadcrumbs items={links} /> */}
      {/* <ProgressStepForm items={stepForm} step={step} /> */}
      <div className="flex flex-col gap-6 mt-3">
        <Card>
          <CardHeader>
            <div>
              Result Chain Project
              <span className="ml-1 text-error-500 text-sm">*</span>
            </div>
          </CardHeader>
          <cardBody>
            {indikatorField.map((el, key) => (
              <>
                <div key={'indikator-' + key} className="mx-4">
                  <div className="flex flex-row p-5">
                    <Input
                      type="checkbox"
                      checked={el.isChecked}
                      disabled={type === 'show'}
                      onChange={() => toggleActive(key)}
                    />
                    <div
                      className="flex-1 flex flex-row justify-between truncate cursor-pointer"
                      onClick={() => toggleAccordion(key)}
                    >
                      <label className="flex-grow mx-3 truncate">
                        {el.indicator.name ?? '-'}
                      </label>
                      <div className="flex justify-items-end">
                        {!openedRs[key] ? (
                          <RiArrowDownSLine />
                        ) : (
                          <RiArrowUpSLine />
                        )}
                      </div>
                    </div>
                  </div>
                  {openedRs[key] && (
                    <div className="flex flex-col p-3 gap-4 bg-[#EAECF0]">
                      <h2>Theory of Change (ToC) Project</h2>
                      <label>Outcome</label>
                      <Input
                        className="w-full form-input"
                        defaultValue={
                          el.outcome !== null ? el?.outcome?.name : '-'
                        }
                        onChange={(e) => setOutcome(el.outcome.name)}
                        disabled
                      />
                      <label>Output</label>
                      <Input
                        className="w-full form-input"
                        defaultValue={
                          el.output !== null ? el?.output?.name : '-'
                        }
                        onChange={(e) => setOutput(el.output.name)}
                        disabled
                      />
                      <label>Sub Output</label>
                      <Input
                        className="w-full form-input"
                        defaultValue={
                          el.suboutput !== null ? el?.suboutput?.name : '-'
                        }
                        onChange={(e) => setSubOutput(el.suboutput.name)}
                        disabled
                      />

                      <label>Persentase</label>
                      <Input
                        className="w-full form-input"
                        defaultValue={
                          el.percentage !== null ? el.percentage : '-'
                        }
                        onChange={(e) => setPercentage(Number(e.target.value))}
                        disabled
                      />
                      {el?.parameter?.map((val, parameterKey) => (
                        <>
                          <h2>Parameter</h2>
                          <div className="flex flex-row">
                            <Input type="checkbox" />
                            <Input
                              hidden
                              onChange={(e) => setParamName(val.nama)}
                            />
                            <span className="ml-3">{val.nama}</span>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div>
                              <label>baseline</label>
                              <Input
                                className="w-full form-input"
                                {...methods.register(
                                  `indikator.${key}.parameter.${parameterKey}.baseline`
                                )}
                              />
                            </div>
                            <div>
                              <label>Target Kontribusi</label>
                              <Input
                                className="w-full form-input"
                                {...methods.register(
                                  `indikator.${key}.parameter.${parameterKey}.target`
                                )}
                              />
                            </div>
                            <div>
                              <label>Satuan/Unit</label>
                              <Input
                                className="w-full form-input"
                                defaultValue={val.satuan}
                                disabled
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ))}
          </cardBody>
        </Card>
      </div>

      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => setCurrentStep({ currentStep: '2' })}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        <div className="block md:flex items-center justify-between gap-2">
          <Button
            type="button"
            className="text-black border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSave)}
            disabled={type === 'show'}
          >
            <RiSave3Line className="text-black" />
            Simpan & Draft
          </Button>
          <Button
            type="button"
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            onClick={methods.handleSubmit(onSubmit)}
          >
            Selanjutnya
            <RiArrowRightLine className="text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddResultChain;
